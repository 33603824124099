import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonLabel, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { informationCircle } from 'ionicons/icons';
import { useParams } from 'react-router';
import { LocalstorageDeleteElement, LocalstorageDirectRead } from '../Logic/Localstorage';
import { homeUri } from '../elements';
import './Page.css';

const SessionPage: React.FC = () => {

  const { name } = useParams<{ name: string; }>();
  // VARIABLES ------------------------------
  // CONDITIONS -----------------------------



  // RETURN ---------------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text={"Indietro"} defaultHref={homeUri} color={'primary'}></IonBackButton>
          </IonButtons>
          <IonTitle>Sessione</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Sessione</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* CONTENT -------------------------- */}
        <div className='ion-padding'>
          <IonLabel>
            <p>Sessione iniziata: </p>
            <h4>{LocalstorageDirectRead.whenTableActivated()}</h4>
            <p>Numero tavolo: </p>
            <h4>{LocalstorageDirectRead.tableNumber()}</h4>
            <p>Pin tavolo: </p>
            <h4>{LocalstorageDirectRead.generatedPinTable()}</h4>
          </IonLabel>
          <br /><br />
          <p>Nel caso di problemi persisitenti di connessione puoi resettare la sessione.</p>
          <IonLabel>
            <p><IonIcon color='danger' icon={informationCircle} />Attenzione! Dovrai inserire il pin di nuovo.</p>
          </IonLabel>
          <br /><br />
          <IonButton expand='block' color={"danger"} fill="clear" onClick={() => { LocalstorageDeleteElement.resetSession(); window.location.reload(); }}>
            Resetta sessione
          </IonButton>

        </div>
        {/* MODALS , ALLERTS ----------------- */}
      </IonContent>
    </IonPage>
  );
};

export default SessionPage;
