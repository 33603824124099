import { IonAvatar, IonButton, IonChip, IonItem, IonLabel, IonSlide, IonSlides } from '@ionic/react';
import './CSS/ProductStandard.css';

interface ContainerProps {
  data: any
}

const SingleProductStandard: React.FC<ContainerProps> = ({ data }) => {

  //VARIABILI -------------------------------
  const slideOptsChips = {
    initialSlide: 0,
    speed: 600,
    slidesPerView: 2,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
    }
  }
  //CONDITIONS ------------------------------

  //RETURN ----------------------------------
  return (
    <IonItem
      className='ion-item product-card'
      routerLink={"/prodotto/" + data.id}
      button
      detail={true}
    >
      <IonAvatar className='padding-avatar ion-item'>
        <img className='img-product' src={data?.immaginiUrl[0]} />
      </IonAvatar>

      <IonLabel className='ion-item ion-padding-top ion-padding-bottom' >
        <div className='grid'>
          <div>
            <h2 className='ion-text-wrap'> <b>{data.nome}</b></h2>
          </div>
          <div>
            <span className='to-right'> <b className='to-right'>€ {data.prezzo}</b> </span>
          </div>
        </div>
        <p>{data.tags[0]} </p>

        <IonSlides options={slideOptsChips}>

          {data?.ingredienti?.map((ingrediente: any, index: number) => {
            return (
              <IonSlide key={index + "product-chip"} className='chip-text '>
                <IonChip color={"primary"} className='chip-text '>
                  <p className='chip-text '>{ingrediente.nome}</p>
                </IonChip>
              </IonSlide>)
          })
          }

        </IonSlides>
        <IonButton fill='solid' expand='block' className='ion-margin-top'>
          Visualizza
        </IonButton>
      </IonLabel>
    </IonItem>

    //--------------------------------------------------------------------------------




  );

};


export default SingleProductStandard;