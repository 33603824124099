import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact, useIonActionSheet, useIonAlert, useIonLoading, useIonToast } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import Menu from './components/Menu';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import HomePage from './pages/HomePage';
import { catalogUri, developerPageUri, homeUri, personalUri, sessionPageUri, tableUri, whoWeAraPageUri } from './elements';
import React from 'react';
import { LocalstorageData, LocalstorageDirectRead, LocalstorageInitializze, LocalstorageWrite } from './Logic/Localstorage';
import { authenticateUser, backendErrors, BackendManagerGetData, BackendManagerRealTable, ServerAction } from './Logic/BackendManager';
import { presentAlertTableNotIdentificatedUI, presentAllertDoppiaSessioneUI, presentToastBenvenutoUI, presentToastErrorsUI, presentToastOrderDoneUI, presentToastTableIdentificatedUI, presentToastTavoloOccupatoUI, presentToastWelcomebackUI } from './Logic/Allerts';
import TablePage from './pages/TablePage';
import PersonalPage from './pages/PersonalPage';
import ProductPage from './pages/ProductPage';
import SessionPage from './pages/SessionPage';
import Products from './pages/Products';
import WhoWeArePage from './pages/WhoWeArePage';
import DeveloperPage from './pages/DeveloperPage';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import { CartFunctions } from './Logic/CapLocal';
import { checkmarkDone } from 'ionicons/icons';
import SectionPage from './pages/SectionScreen';
import CategoryPage from './pages/CategoryPage';
import AllProductsPage from './pages/AllProductsPage';
import SingleProductPage from './pages/SingleProductPage';
import NewsPage from './pages/NewsPage';
import AllNewsPage from './pages/AllNewsPage';
import BookPage from './pages/BookPage';
import SingleCategoryPage from './pages/SingleCategoryPage';
import TUTORIAL_TableScreen from './pages/TUTORIAL_TableScreen';

setupIonicReact();

interface order {
  name: string,
  products: []
}


const App: React.FC = () => {
  //UI RESPONSE -----------------------------
  const [presentAlertTableNotIdentificated] = useIonAlert();
  const [presentToastTableIdentificated] = useIonToast();
  const [presentAlertConnectWithPin] = useIonAlert();
  const [presentToastErrors] = useIonToast();
  const [presentToastWarning] = useIonToast();
  const [presentToastWelcomeback] = useIonToast();
  const [presentToastOrderDone] = useIonToast();
  const [presentAllertDoppiaSessione] = useIonAlert();
  const [presentAllertSetTableNumber] = useIonAlert();
  const [presentToastErrorTableNotExisting] = useIonToast();
  const [presentToastBenvenuto] = useIonToast();
  const [presentShareAction] = useIonActionSheet();
  // VARIABLES ------------------------------

  //adapt
  const [data, setData] = React.useState<any>();
  const [uniqueProducts, setUniqueProducts] = React.useState<any[]>();
  const [uniqueCategory, setUniqueCategory] = React.useState<any[]>();
  const [present, dimiss] = useIonLoading();
  const [presentToast] = useIonToast();

  const [cart, setCart] = React.useState<any[]>([]);
  //fine ---- adapt

  const [products, setProducts] = React.useState<any>(LocalstorageDirectRead.ordersInCart());
  const [orders, setOrders] = React.useState<any>([]);

  let ordersArr: any = [];

  // FUNCIONS -------------------------------

  const handleGetUniqueProducts = (dataPage: any) => {
    let uniqueProducts: any[] = [];
    dataPage?.sezioni?.map((sezione: any) => {
      sezione?.categorie?.map((categoria: any) => {
        categoria?.prodotti.map((prodotto: any) => {
          if ((uniqueProducts.find((prod: any) => prod.id === prodotto.id) === undefined)) {
            uniqueProducts.push(prodotto);
          }
        })
      })
    })
    setUniqueProducts(uniqueProducts);
  }

  const handleGetUniqueCategory = (dataPage: any) => {
    let uniqueCategory: any[] = [];
    dataPage?.sezioni?.map((sezione: any) => {
      sezione?.categorie?.map((categoria: any) => {
        if ((uniqueCategory.find((cat: any) => cat.id === categoria.id) === undefined)) {
          uniqueCategory.push(categoria);
        }
      })
    })
    setUniqueCategory(uniqueCategory);
  }


  const handleAddToCart = (prodotto: any, removedIngredients: any[]) => {
    let obj = {
      product: prodotto,
      withOut: removedIngredients,
    }
    setCart(cart.concat(obj));

    if (cart.length > 0) {
      let auxLocal = [...cart];
      CartFunctions.saveProducts(auxLocal.concat(obj));
    } else {
      CartFunctions.saveProducts([obj]);
    }
    presentToast({
      message: 'Prodotto aggiunto al carrello',
      duration: 1000,
      color: "success",
      position: "top",
      icon: checkmarkDone,
    });

  }

  const handleRemoveFromCart = (index?: number) => {
    console.log("handleRemoveFromCart : ", index);
    let newCart = cart.filter((_, i) => i !== index);
    console.log(newCart);
    CartFunctions.saveProducts(newCart);
    setCart(newCart);
  }

  const productsFunctions = {
    add: function addProduct(product: any) {
      setProducts(products.concat(product));
    },
    remove: function deleteProduct(index: any) {
      return setProducts((product: any) =>
        products.filter((_: any, i: any) => i !== index)
      );
    },
    getProducts: function getProducts() {
      return products;
    },
    reset: function resetProducts() {
      return setProducts([]);
    },
  }

  const ordersFunctions = {
    effettuaOrdine: async function addOrder(history: any) {
      let prodottiPerOrdine = {
        oraOrdine: new Date().toLocaleTimeString(),
        prodotti: await CartFunctions.getProducts()
      };
      let orderStatus = false;
      await ServerAction.addOrderToTable(LocalstorageData.getTableNumber()!, LocalstorageData.getGeneratedPinTable()!, await CartFunctions.getProducts(), presentToastErrorTableNotExisting).catch(async (error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        orderStatus = true;
        // LocalstorageWrite.writeOrdersInCart(productsFunctions.getProducts()); //In teoria non serve più
        console.log("FIREBASE ERROR: ", errorCode, errorMessage);
        presentToastErrorsUI(presentToastErrors, "Errore di sessione.");
      }).then(async () => {
        if (orderStatus == false) {
          presentToastOrderDoneUI(presentToastOrderDone, history); //In teoria si può semplificare

          ordersArr.push(prodottiPerOrdine);
          CartFunctions.saveProducts([]);
          setCart([]);

          // LocalstorageDeleteElement.ordersInCart(); //In teoria non serve più
          // productsFunctions.reset(); //In teoria non serve più
        }
      });
    },

    remove: function deleteOrder(index: any) {
      return setOrders((order: order) =>
        orders.filter((_: any, i: any) => i !== index)
      );
    },
    getOrders: function getOrders() {
      return orders;
    }
  }

  const initilizzeApp = async () => {
    console.log("FASE 4 - Leggi le informazioni del local storage");
    LocalstorageInitializze();
    console.log("FASE 5 - Autentica l'utente");
    await authenticateUser().then(() => {
      console.log("BackendManagerData.getUserUID : ", BackendManagerGetData.getUserUID())
    }).then(async () => {
      console.log("FASE 6 - Leggi dal local storage il numbero del tavolo")
      if (
        LocalstorageDirectRead.tableNumber() == null ||
        LocalstorageDirectRead.tableNumber() == undefined ||
        LocalstorageDirectRead.tableNumber()?.length == 0
      ) {
        presentAlertTableNotIdentificatedUI(presentAlertTableNotIdentificated);
      } else {
        if (
          LocalstorageData.getGeneratedPinTable() == null ||
          LocalstorageData.getGeneratedPinTable() == undefined ||
          LocalstorageData.getGeneratedPinTable()?.length == 0
        ) {
          console.log("FASE 7 - Numero tavolo scritto: ", LocalstorageDirectRead.tableNumber(), " - Pin del tavolo: ", LocalstorageData.getGeneratedPinTable());
          presentToastTableIdentificatedUI(presentToastTableIdentificated);
          await BackendManagerRealTable.hanldeReserveTable(LocalstorageData.getTableNumber()!).then(() => {
            if (backendErrors.tableNotFree.state == true) {
              presentToastTavoloOccupatoUI(presentToastErrors, presentAlertConnectWithPin, presentAlertTableNotIdentificated, presentToastWarning, presentToastBenvenuto);
            }
            else {
              presentToastBenvenutoUI(presentToastBenvenuto);
            }
          })
        } else {
          //Prima di dare il welcome back:
          // CHECK SE: getTableNumber == getCurrentSession:
          if (LocalstorageDirectRead.tableNumber() == LocalstorageDirectRead.currentSession()) {
            // ALLORA: procedi
            presentToastWelcomebackUI(presentToastWelcomeback, LocalstorageData.getTableNumber()!);
          } else {
            // SE NO: presenta il allert "attualemente sei collegato in un'altra sessione. Abbandonare sessione?"
            presentAllertDoppiaSessioneUI(presentAllertDoppiaSessione, presentAlertTableNotIdentificated, LocalstorageDirectRead.currentSession(), LocalstorageDirectRead.tableNumber());

            //CHECK SE: Abbandonare sessione:
            //ALLORA: Presentare conto sessione vecchia.
            //SE NO: Sostituire table number con current seesion. 
          }

        }
      }
    })
  }


  // CONDITIONS -----------------------------

  React.useState(async () => {
    console.log("FASE 1 - present alert")
    // presentAllertSetTableNumber({
    //   header: 'Numero tavolo',
    //   subHeader: 'Attenzione, inserire il numero del tavolo',
    //   onDidDismiss: () => { initilizzeApp() },
    //   inputs: [
    //     {
    //       name: 'numberTable',
    //       type: 'tel'
    //     }],
    //   buttons: [
    //     {
    //       text: 'Attiva',
    //       role: 'Attiva',
    //       handler: (alertData: any) => {
    //         console.log("Fase 2 - scrivi sul local storage TABLE NUMBER :", alertData.numberTable);
    //         let x = parseInt(alertData.numberTable)
    //         if (x < 10) {
    //           if (alertData.numberTable.charAt(0) != "0") {
    //             console.log("X MINIORE DI 10")
    //             alertData.numberTable = "0" + alertData.numberTable;
    //           }
    //         }

    //         LocalstorageWrite.writeTableNumber(alertData.numberTable);
    //         console.log("Fase 3 - Initializze App")
    //         initilizzeApp();
    //       },
    //     },
    //   ],
    // });

    present({
      message: "Sto caricando i dati..."
    })
    const docRef = doc(db, "attivita", "1677579610322"); //CODICE ATTIVITA'
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      setData(docSnap.data())
      dimiss();
      handleGetUniqueProducts(docSnap.data());
      handleGetUniqueCategory(docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
      setData(docSnap.data())
      dimiss();
    }

    setCart(await CartFunctions.getProducts());

    initilizzeApp();
  })
  // RETURN ---------------------------------
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu uniqueCategory={uniqueCategory} />

          <IonRouterOutlet id="main">

            <Route path="/" exact={true}>
              <Redirect to={homeUri} />
            </Route>

            {/* HOME PAGE */}
            <Route path={homeUri} exact={true}>
              <HomePage
                ordersFunctions={ordersFunctions}
                presentShareAction={presentShareAction}
                data={data}
                handleRemoveFromCart={(index) => handleRemoveFromCart(index)}
                cartItemsNumber={cart.length}
              />
            </Route>

            {/* SECTION PAGE */}
            <Route path="/menu/:indiceSezione" exact={true}>
              <SectionPage
                ordersFunctions={ordersFunctions}
                data={data}
                handleRemoveFromCart={(index) => handleRemoveFromCart(index)}
                cartItemsNumber={cart.length}
              />
            </Route>

            {/* CATEGORY PAGE */}
            <Route path="/menu/:indiceSezione/:indiceCategoria" exact={true}>
              <CategoryPage
                ordersFunctions={ordersFunctions}
                data={data}
                handleRemoveFromCart={(index) => handleRemoveFromCart(index)}
                cartItemsNumber={cart.length}
              />
            </Route>
            {/* CATEGORY PAGE */}
            <Route path="/categoria/:idCategoria" exact={true}>
              <SingleCategoryPage
                ordersFunctions={ordersFunctions}
                dataUniqueCategory={uniqueCategory}
                handleRemoveFromCart={(index) => handleRemoveFromCart(index)}
                cartItemsNumber={cart.length}
              />
            </Route>

            {/* PRODUCT PAGE */}
            <Route path="/menu/:indiceSezione/:indiceCategoria/:indiceProdotto" exact={true}>
              <ProductPage
                ordersFunctions={ordersFunctions}
                data={data}
                addToCard={handleAddToCart}
                handleRemoveFromCart={(index) => handleRemoveFromCart(index)}
                cartItemsNumber={cart.length}
              />
            </Route>

            {/* ALL PRODUCTS */}
            <Route path="/prodotti" exact={true}>
              <AllProductsPage
                ordersFunctions={ordersFunctions}
                data={uniqueProducts}
                handleRemoveFromCart={(index) => handleRemoveFromCart(index)}
                cartItemsNumber={cart.length}
              />
            </Route>

            {/* PRODUCT FROM ALL PRODUCTS */}
            <Route path="/prodotto/:idProdotto" exact={true}>
              <SingleProductPage
                ordersFunctions={ordersFunctions}
                dataUniqueProducts={uniqueProducts}
                addToCard={handleAddToCart}
                cartItemsNumber={cart.length}
                handleRemoveFromCart={(index) => handleRemoveFromCart(index)}
              />
            </Route>

            {/* COMUNICAZIONI */}
            <Route path="/comunicazioni" exact={true}>
              <AllNewsPage
                data={data}
              />
            </Route>
            <Route path="/comunicazioni/:nomeNews" exact={true}>
              <NewsPage
                data={data}
              />
            </Route>

            {/* ORARI */}
            <Route path="/orari" exact={true}>
              <BookPage
                data={data}
              />
            </Route>
            {/* ORARI */}
            <Route path="/tutorial" exact={true}>
              <TUTORIAL_TableScreen />
            </Route>


            <Route path={tableUri} exact={true}>
              <TablePage
                productsFunctions={productsFunctions}
                ordersFunctions={ordersFunctions}
              />
            </Route>

            <Route path={sessionPageUri} exact={true}>
              <SessionPage />
            </Route>

            <Route path={whoWeAraPageUri} exact={true}>
              <WhoWeArePage data={data} />
            </Route>

            <Route path={catalogUri + "/:name"} exact={true}>
              <Products />
            </Route>

            <Route path={developerPageUri} exact={true}>
              <DeveloperPage />
            </Route>

            <Route path={personalUri} exact={true}>
              <PersonalPage data={data} />
            </Route>

          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
