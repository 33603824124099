import { IonBackButton, IonBadge, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonLabel, IonList, IonPage, IonSearchbar, IonTitle, IonToolbar } from '@ionic/react';
import { cartSharp } from 'ionicons/icons';
import React from 'react';
import { useParams } from 'react-router';
import Cart from '../components/Cart';
import ProductStandard from '../components/ProductStandard';
import './CSS/CategoryPage.css';


type PageProps = {
  data: any,
  handleRemoveFromCart: (index?: number) => void;
  cartItemsNumber: number;
  ordersFunctions: any
}


const CategoryPage: React.FC<PageProps> = ({ data, handleRemoveFromCart, cartItemsNumber, ordersFunctions }) => {

  //VARIABILI --------------------------------------------
  const { indiceCategoria, indiceSezione } = useParams<{ indiceCategoria: string; indiceSezione: string, }>();

  const sectionIndex = indiceCategoria.split('-')[1];
  const catorgoryIndex = indiceCategoria.split('-')[2];

  const dataCategoria = data.sezioni[parseInt(sectionIndex)].categorie[parseInt(catorgoryIndex)];

  const [searchTextModale, setSearchTextModale] = React.useState('');

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  //CONDIZIONI -------------------------------------------

  const handleSearch = (testoCercato: string) => {
    let arr = Array.from(window.document.getElementsByClassName("product-card"));
    const query = testoCercato.toLowerCase();
    requestAnimationFrame(() => {
      arr.forEach((item: any) => {
        const shouldShow = item.textContent.toLowerCase().indexOf(query) > -1;
        item.style.display = shouldShow ? 'block' : 'none';
      });
    });
  }


  //RETURN -----------------------------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text={"Indietro"} defaultHref={"/menu/" + indiceCategoria} />
          </IonButtons>
          <IonTitle>{dataCategoria.nome}</IonTitle>
          <IonButtons slot='end'>
            <IonButton onClick={() => { setIsModalOpen(!isModalOpen) }}>
              <IonIcon icon={cartSharp} />
              <IonBadge slot="end">{cartItemsNumber}</IonBadge>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <Cart ordersFunctions={ordersFunctions} isOpen={isModalOpen} setIsOpen={() => setIsModalOpen(false)} handleRemoveFromCart={(ev) => { handleRemoveFromCart(ev); }} />


      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{dataCategoria.nome}</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* CONTENUTO */}

        <IonSearchbar value={searchTextModale} placeholder={"Cerca prodotto"} onIonChange={e => { setSearchTextModale(e.detail.value!); handleSearch(e.detail.value!) }}></IonSearchbar>


        {/* descrizione */}
        <div className='ion-padding'>
          <IonLabel>
            <p>Descrizione</p>
          </IonLabel>
          <p>{dataCategoria.descrizione}</p>
        </div>

        {/* categorie */}
        <div>
          <IonLabel>
            <p className='ion-padding-horizontal'>Prodotti</p>
          </IonLabel>
          <IonList className='ion-item'>
            {dataCategoria?.prodotti.map((prodotto: any, index: number) => {
              return (
                <div key={"dataCategoria-prodotto" + dataCategoria.nome + "index" + index}>
                  <ProductStandard
                    indiceSezione={sectionIndex}
                    indiceCategoria={catorgoryIndex}
                    data={prodotto}
                    nomeSezione={indiceSezione}
                    nomeCategoria={dataCategoria.nome}
                    index={index} />
                </div>
              )
            })}
          </IonList>
        </div>


      </IonContent>
    </IonPage>
  );
};

export default CategoryPage;
