import { catalogUri, tableUri } from "../elements";
import { backendErrors, BackendManagerRealTable} from "./BackendManager";
import { LocalstorageData, LocalstorageDeleteElement, LocalstorageDirectRead, LocalstorageInitializze, LocalstorageWrite } from "./Localstorage";


let alertDimissedWithOutPin: boolean = true;


export const presentToastErrorsUI = (presentToastErrors: any, message: string) => {
     return presentToastErrors({
          message: message,
          color: "danger",
          position: 'top',
          buttons: [{
               text: 'Ricarica',
               role: 'Ricarica',
               handler: async () => { window.location.href = "" }
          }]
     })
}

export const presentToastPersonalCheckUI = (presentPersonalCheck: any, message: string) => {
     return presentPersonalCheck({
          message: message,
          color: "primary",
          position: 'top',
          buttons: [{
               text: 'Chiudi',
               role: 'Chiudi',
               handler: async () => { window.location.href = "" }
          }]
     })
}

export const presentToastErrorsTableNotExistUI = (presentToastErrors: any) => {
     return presentToastErrors({
          message: "Errore. Ripristina sessione.",
          color: "danger",
          position: 'top',
          buttons: [{
               text: 'Ripristina',
               role: 'Ripristina',
               handler: async () => { LocalstorageDeleteElement.notTableNumber(); window.location.href = "" }
          }]
     })
}

export const presentToastEmptyCartUI = (presentToastEmptyCart: any, history: any) => {
     return presentToastEmptyCart({
          message: "Carrello vuoto. Esplora il catalogo",
          color: "warning",
          position: 'top',
          duration: 1500,
          buttons: [{
               text: 'Catalogo',
               role: 'Catalogo',
               handler: async () => {
                    history.push(catalogUri);
               }
          }]
     })
}

export const presentAllertDoppiaSessioneUI = (presentAllertDoppiaSessione: any, presentAlertTableNotIdentificated: any, currentSession: any, tableNumber: any) => {
     return presentAllertDoppiaSessione({
          header: 'Attenzione!',
          subHeader: 'Hai una sessione attiva al tavolo ' + currentSession + " e stai provando ad accedere al tavolo " + tableNumber,
          message: 'Torna al tavolo ' + currentSession + ' e chiedi il conto.',
          buttons: [
               {
                    text: 'Entra al tavolo ' + currentSession,
                    role: 'Chiudi',
                    handler: async () => {
                         LocalstorageDeleteElement.resetSession()
                         alertDimissedWithOutPin = true;
                         console.log("Write to localstorage new table number and reload")
                         // LocalstorageWrite.writeTableNumber(currentSession);
                         window.location.href = ""
                    },
               },
          ],
          onDidDismiss: () => {
               if (alertDimissedWithOutPin == true) {
                    LocalstorageDeleteElement.resetSession()
                    setTimeout(presentAlertTableNotIdentificatedUI(presentAlertTableNotIdentificated), 1000);
               }
          },
     })
}

export const presentToastTavoloOccupatoUI = (presentToastErrors: any, presentAlertConnectWithPin: any, presentAlertTableNotIdentificated: any, presentToastWarning: any, presentToastBenvenuto: any) => {
     return presentToastErrors({
          message: "Tavolo occupato. Inserisci pin.",
          color: "warning",
          position: 'top',
          buttons: [{
               text: 'Inserisci',
               role: 'Inserisci',
               handler: async () => { presentAlertConnectWithPinUI(presentAlertConnectWithPin, presentAlertTableNotIdentificated, presentToastWarning, presentToastBenvenuto) }
          }]
     })
}

export const presentToastWarningUI = (presentToastWarning: any, message: string, presentAlertConnectWithPin: any, presentAlertTableNotIdentificated: any, presentToastBenvenuto: any) => {
     return presentToastWarning({
          message: message,
          color: "warning",
          position: 'top',
          buttons: [{
               text: 'Riprova',
               role: 'Riprova',
               handler: async () => { presentAlertConnectWithPinUI(presentAlertConnectWithPin, presentAlertTableNotIdentificated, presentToastWarning, presentToastBenvenuto) }
          }]
     })
}

export const presentToastAddedToCartUI = (presentToastAddedToCart: any, history: any) => {
     return presentToastAddedToCart({
          message: "Aggiunto al carrello!",
          color: "success",
          position: 'top',
          duration: 500,
     })
}

export const presentToastWelcomebackUI = (presentToastWelcomeback: any, tableNumber: string) => {
     return presentToastWelcomeback({
          message: "Bentornato al tavolo " + tableNumber,
          color: "primary",
          position: 'top',
          duration: 1000,
     })
}

export const presentAlertTableNotIdentificatedUI = (presentAlertTableNotIdentificated: any) => {
     return presentAlertTableNotIdentificated({
          header: 'Attenzione',
          subHeader: 'Tavolo non identificato',
          message: 'Prova a scansionare nuovamente il Qr Code',
          onDidDismiss: () => { 
               LocalstorageDeleteElement.resetSession()
               window.location.href = "" 
          },
          buttons: [
               {
                    text: 'Ok',
                    role: 'Ok',
                    handler: () => {
                         LocalstorageDeleteElement.resetSession()
                         window.location.href = ""
                    },
               },
          ],

     })
}

export const presentToastBenvenutoUI = (presentToastBenvenuto: any) => {
     return presentToastBenvenuto({
          message: "Benvenuto al tavolo " + LocalstorageDirectRead.tableNumber() + ". Ora puoi ordinare!",
          color: "primary",
          position: 'top',
          duration: 1000,
     })
}

export const presentAlertConnectWithPinUI = (presentAlertConnectWithPin: any, presentAlertTableNotIdentificated: any, presentToastWarning: any, presentToastBenvenuto: any) => {
     return presentAlertConnectWithPin({
          header: 'Inserire pin tavolo',
          subHeader: 'Il pin lo possiede colui che ha attivato il tavolo.',
          message: '',
          buttons: [
               {
                    text: 'Entra',
                    role: 'Entra',
                    handler: async (alertPin: any) => {
                         alertDimissedWithOutPin = false;
                         await BackendManagerRealTable.handleConnectWithPin(alertPin.pin, LocalstorageData.getTableNumber()!).then(() => {
                              if (backendErrors.wrongPin.state == true) {
                                   // alertDimissedWithOutPin = true;
                                   presentToastWarningUI(presentToastWarning, "Pin errato.", presentAlertConnectWithPin, presentAlertTableNotIdentificated, presentToastBenvenuto);
                              } else {
                                   // alertDimissedWithOutPin = true;
                                   console.log("Benvenuto al tavolo.");
                                   presentToastBenvenutoUI(presentToastBenvenuto);
                              }
                         });
                    },
               },
          ],
          onDidDismiss: () => {
               if (alertDimissedWithOutPin == true) {
                    setTimeout(presentAlertTableNotIdentificatedUI(presentAlertTableNotIdentificated), 1000);
               }
          },
          inputs: [
               {
                    type: 'tel',
                    name: 'pin',
                    placeholder: 'es: 154',
                    attributes: {
                         maxlength: 3,
                    },
               }
          ],
     })
}

export const presentToastTableIdentificatedUI = (presentToastTableIdentificated: any) => {
     return presentToastTableIdentificated({
          message: 'Benvenuto al tavolo ' + LocalstorageData.getTableNumber(),
          color: "success",
          position: 'top',
          duration: 500,
          buttons: [
               {
                    text: '',
                    role: '',
                    handler: async () => {
                        
                    }
               }
          ]
     })
}

export const presentToastOrderDoneUI = (presentToastOrderDone: any, history: any) => {
     return presentToastOrderDone({
          message: "Ordine effettuato. ",
          color: "success",
          position: 'top',
          duration: 1500,
          buttons: [{
               text: 'Apri Tavolo',
               role: 'Apri Tavolo',
               handler: async () => {
                    history.push(tableUri);
               }
          }]
     })
}

export const presentShareActionUI = (presentShareAction: any) => {
     return presentShareAction({
          header: 'Tavolo ' + LocalstorageDirectRead.tableNumber(),
          buttons: [
               // {
               //      text: 'WhatsApp',
               //      role: 'Share',
               //      handler: async () => {
               //           shareLink();
               //      }
               // },
               {
                    text: 'Il pin del tavolo è ' + LocalstorageDirectRead.generatedPinTable(),
                    data: {
                         action: 'share',
                    },
               },
               {
                    text: 'Chiudi',
                    role: 'cancel',
                    data: {
                         action: 'cancel',
                    },
               },
          ],
     })
}

