import { IonButton, IonChip, IonContent, IonIcon, IonImg, IonPage, IonSlide, IonSlides, } from '@ionic/react';
import { arrowForward } from 'ionicons/icons';

import './CSS/TUTORIAL_TableScreen.css';

interface PageProps {
  tutorialDone?: () => void; //Se voglio farlo se no easy
}

const TUTORIAL_TableScreen: React.FC<PageProps> = ({ tutorialDone }) => {

  //VARIABILI --------------------------------------------
  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    spaceBetween: 5
  };
  //CONDIZIONI -------------------------------------------
  //RETURN -----------------------------------------------
  return (
    <IonPage>
      <IonContent fullscreen>
        <IonSlides
          pager={true}
          options={slideOpts}
          onIonSlideDidChange={(ev) => { console.log(ev) }}
          onIonSlideWillChange={(ev) => { console.log(ev) }}
          className="TUTORIAL_TableScreen-Slides"
        >
          <IonSlide className='TUTORIAL_TableScreen-Slide'>
            <div className='TUTORIAL_TableScreen-fullpagediv ion-padding'>
              <div className='TUTORIAL_TableScreen-divImg'>
                <IonImg className='TUTORIAL_TableScreen-img' src={"https://firebasestorage.googleapis.com/v0/b/menushare-a4100.appspot.com/o/logos%2FmenuShareLogo.jpg?alt=media&token=6f02aee1-26d7-4eca-9714-c3ba4515abfd"} />
              </div>
              <div className='TUTORIAL_TableScreen-content'>
                <h3 className='ion-text-center'>Come funzionano i tavoli</h3>
                <p>
                  Una breve introduzione all'utilizzo corretto dei tavoli, per permetterti di effettuare gli ordini al tuo tavolo.
                </p>
              </div>
            </div>
          </IonSlide>
          <IonSlide className='TUTORIAL_TableScreen-Slide'>
            <div className='TUTORIAL_TableScreen-fullpagediv ion-padding'>
              <div className='TUTORIAL_TableScreen-divImg'>
                <IonImg className='TUTORIAL_TableScreen-img' src={"https://firebasestorage.googleapis.com/v0/b/menushare-a4100.appspot.com/o/logos%2FEsempioQrCodeTavolo.png?alt=media&token=e99cd6a2-c129-416b-b0a3-57860c8a6410"} />
              </div>
              <div className='TUTORIAL_TableScreen-content'>
                <h3 className='ion-text-center'>Collegamento al tavolo</h3>
                <p>
                  Se non sei già collegato, dovrai inserire il codice del tavolo (scritto subito sotto il QrCode) oppure il codice di chi è già seduto: così da associare i tuoi ordini al tavolo specificato.
                </p>
                <p>
                  Se sei il primo o da solo, il tavolo sarà riservato a te finchè non chiederai il conto, e ti verrà assegnato un codice pin da poter condividere se stai aspettando qualcuno.
                </p>
              </div>
            </div>
          </IonSlide>
          <IonSlide className='TUTORIAL_TableScreen-Slide'>
            <div className='TUTORIAL_TableScreen-fullpagediv ion-padding'>
              <div className='TUTORIAL_TableScreen-divImg'>
                <IonImg className='TUTORIAL_TableScreen-img' src={"https://firebasestorage.googleapis.com/v0/b/menushare-a4100.appspot.com/o/logos%2FInCompagnia.jpg?alt=media&token=cd8be57c-c639-4be7-9643-4f8dfc50a108"} />
              </div>
              <div className='TUTORIAL_TableScreen-content'>
                <h3 className='ion-text-center'>Gli ordini</h3>
                <p>
                  La pagina tavolo, farà vedere tutti gli ordini effettuati, sia tuoi sia di chi si è unito grazie al tuo codice. Ogni ordine ha uno "Stato":
                </p>
                <p>
                  <IonChip color={"warning"}>Ordine effetuato</IonChip>
                  <IonChip color={"primary"}>Preparazione in corso</IonChip>
                  <IonChip color={"success"}>Consegnato</IonChip> <br /><br />

                  e gli aggiornamenti sono in tempo reale, quindi se sei curioso tieni d'occhio il tuo ordine.

                </p>
              </div>
            </div>
          </IonSlide>
          <IonSlide className='TUTORIAL_TableScreen-Slide'>
            <div className='TUTORIAL_TableScreen-fullpagediv ion-padding'>
              <div className='TUTORIAL_TableScreen-divImg'>
                <IonImg className='TUTORIAL_TableScreen-img' src={"https://firebasestorage.googleapis.com/v0/b/menushare-a4100.appspot.com/o/logos%2Fpagamento.jpg?alt=media&token=894f968c-b658-4dd1-aca1-e78c0f706142"} />
              </div>
              <div className='TUTORIAL_TableScreen-content'>
                <h3 className='ion-text-center'>Pagamento</h3>
                <p>
                  Il pagamento viene effettuato in cassa.
                </p>
                <p>
                  Potrai scegliere se richiedere il pagamento personale, o richiedere il totalone.
                </p>
                <p>Una volta selezionato, vai direttamente in cassa.</p>
              </div>
              <IonButton fill='clear' routerLink='/' onClick={() => { }}>
                Fine <IonIcon icon={arrowForward} className="ion-margin-start" />
              </IonButton>
            </div>
          </IonSlide>
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};

export default TUTORIAL_TableScreen;
