import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
     apiKey: "AIzaSyC7psonJnen7YDOylt8B1V0_PnZaDpXUx8",
     authDomain: "menushare-a4100.firebaseapp.com",
     projectId: "menushare-a4100",
     storageBucket: "menushare-a4100.appspot.com",
     messagingSenderId: "617191314253",
     appId: "1:617191314253:web:dcf2d16a0c1e2f51909d04",
     measurementId: "G-9619T2TYWL"
};


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const storage = getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth(app);


export const VirtualTables = "VirtualTables";
export const RealTables = "RealTables";
export const StoricoTavoli = "StoricoTavoli";
