import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonList, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { doc, getDoc } from 'firebase/firestore';
import { chevronBack } from 'ionicons/icons';
import { useState } from 'react';
import { useParams } from 'react-router';
import { loadProductTest } from '../Logic/BackendManager';
import ProductStandard from '../components/ProductStandard';
import { backButtonText, homeUri } from '../elements';
import { db } from '../firebase';
import './Page.css';

const Products: React.FC = () => {

  const { name } = useParams<{ name: string; }>();
  // VARIABLES ------------------------------
  const [products, setProducts] = useState<any>([]);
  let arr: any = [];
  // CONDITIONS -----------------------------


  useState(async () => {
    const docRef = doc(db, "Products", "Homepage");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      setProducts(docSnap.data());
      arr.push(docSnap.data())
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  })

  // RETURN ---------------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton routerDirection={"back"} routerLink={homeUri}>
              <IonIcon icon={chevronBack} />
              {backButtonText}
            </IonButton>
          </IonButtons>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* CONTENT -------------------------- */}
        <IonButton expand='block' onClick={() => loadProductTest()}>
          Carica Prodotti
        </IonButton>

        <div className='ion-padding'>
          <IonList className='ion-item padding-zero'>
            {/* {products.dataProducts?.map((product: any, index: number) => {
              return (
                <ProductStandard key={index + "ProductsPage" + name} obj={product} productsFunctions={"productsFunctions"} presentToastAddedToCart={"presentToastAddedToCart"} />
              )
            })} */}
          </IonList>
        </div>



        {/* MODALS , ALLERTS ----------------- */}
      </IonContent>
    </IonPage>
  );
};

export default Products;
