import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonContent, IonHeader, IonIcon, IonMenuButton, IonPage, IonSlide, IonSlides, IonTitle, IonToolbar } from '@ionic/react';
import { callOutline, globeOutline, logoFacebook, logoInstagram, logoLinkedin } from 'ionicons/icons';
import './Page.css';

type PageProps = {
  data: any
}

const WhoWeArePage: React.FC<PageProps> = ({ data }) => {

  // VARIABLES ------------------------------

  const dataPage = data?.about;
  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    spaceBetween: 5
  };

  const slideOptsImmagini = {
    initialSlide: 0,
    speed: 2000,
    spaceBetween: 10,
    slidesPerView: 1,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    }
  }

  // CONDITIONS -----------------------------


  // RETURN ---------------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Chi siamo</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Chi siamo</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* CONTENUTO */}

        <div>
          <IonSlides pager={true} options={slideOpts}>
            {dataPage?.immaginiUrl?.map((imgUrl: string, index: number) => {
              return (
                <IonSlide key={index + "slide-immagineAbout"}>
                  <div key={index + "div-img-about"} className='div-img-about'>
                    <img src={imgUrl} alt={"immagine locale "} className="img-about" />
                  </div>
                </IonSlide>)
            })}
          </IonSlides>
        </div>
        <div className='ion-padding-horizontal'>
          {dataPage?.descrizione}
        </div>

        <div>
          <IonCard>
            <IonCardHeader>
              <IonCardSubtitle>
                Contatti
              </IonCardSubtitle>
              <IonCardContent>
                {dataPage?.telefono?.map((contatto: any, index: number) => {
                  return (
                    <IonButton fill='outline' expand='block' key={index + "numerotelefono" + contatto.nome} onClick={() => window.open("tel:" + contatto.numero)}>
                      <IonIcon slot='start' icon={callOutline} />
                      {contatto.nome}  {contatto.numero}
                    </IonButton>
                  )
                })}
              </IonCardContent>
            </IonCardHeader>
          </IonCard>
        </div>
        <div>
          <IonCard>
            <IonCardHeader>
              <IonCardSubtitle>
                Ci trovi anche qui
              </IonCardSubtitle>
              {dataPage?.facebook ?
                <IonButton fill='clear' onClick={() => window.open(dataPage?.facebook, "_blank")}>
                  <IonIcon icon={logoFacebook} />
                </IonButton>
                : null}
              {dataPage?.instagram ?
                <IonButton fill='clear' onClick={() => window.open(dataPage?.instagram, "_blank")}>
                  <IonIcon icon={logoInstagram} />
                </IonButton>
                : null}
              {dataPage?.linkedin ?
                <IonButton fill='clear' onClick={() => window.open(dataPage?.linkedin, "_blank")}>
                  <IonIcon icon={logoLinkedin} />
                </IonButton>
                : null}
              {dataPage?.sitoWeb ?
                <IonButton fill='clear' onClick={() => window.open(dataPage?.sitoWeb, "_blank")}>
                  <IonIcon icon={globeOutline} />
                </IonButton>
                : null}
            </IonCardHeader>
          </IonCard>
        </div>


      </IonContent>
    </IonPage>
  );
};

export default WhoWeArePage;
