import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonChip, IonImg, IonSlide, IonSlides } from '@ionic/react';
import './CSS/CategoryCard.css';


type CategoryData = {
  indiceSezione: string,
  data: any[],
  nomeSezione: string,
}

const CategoryCard: React.FC<CategoryData> = ({ data, indiceSezione, nomeSezione }) => {
  //VARIABILI --------------------------------------------
  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    slidesPerView: 3,
    autoplay: {
      delay: 1200,
    },
    spaceBetween: 0,
  };
  //CONDIZIONI -------------------------------------------

  //RETURN -----------------------------------------------
  return (
    <div>
      {data?.map((data: any, index: number) => {
        return (
          <IonCard
            key={index + "card-homeScreenSection"}
            routerLink={"/menu/" + nomeSezione + "/" + data.nome + "-" + indiceSezione + "-" + index}
          >
            <IonImg alt={"immagine-categoria" + data.nome} className="card-img" src={data.immagineUrl} />
            <IonCardHeader>
              <IonCardTitle>{data.nome}</IonCardTitle>
            </IonCardHeader>

            <IonCardContent >
              <p className='descrizione'>{data.descrizione}</p>
              <div className='chip-tags'>
                <IonSlides pager={false} options={slideOpts}>
                  {data.tags?.map((tagValue: string, index: number) => {
                    return (
                      <IonSlide key={index + "slide-CategoryCard" + data.nome}>
                        <IonChip key={index + data.nome + "CategoryCard" + tagValue} color="success">{tagValue}</IonChip>
                      </IonSlide>
                    )
                  })}
                </IonSlides>
              </div>

            </IonCardContent>
          </IonCard>
        )
      })
      }
    </div>
  )
};

export default CategoryCard;
