import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonSlide, IonSlides } from '@ionic/react';
import './CSS/HeaderCards.css';


type HeaderSliderData = {
  data: any[],
}

const HeaderCards: React.FC<HeaderSliderData> = ({ data }) => {
  //VARIABILI --------------------------------------------
  const slideOpts = {
    initialSlide: 0,
    speed: 400,
  };
  //CONDIZIONI -------------------------------------------

  //RETURN -----------------------------------------------
  return (
    <IonSlides pager={true} options={slideOpts}>
      {data?.map((headerData: any, index: number) => {
        return (
          <IonSlide key={index + "slide-homeScreenHeader"}>
            <div key={index + "div-homeScreenHeader"} className='div-slide'>
              <IonCard
                key={index + "card-homeScreenHeader"}
                routerLink={"/comunicazioni/" + headerData.nome + "-" + index}
              >
                <IonCardHeader>
                  <IonCardSubtitle>{headerData.sottotitolo}</IonCardSubtitle>
                  <IonCardTitle>{headerData.nome}</IonCardTitle>
                </IonCardHeader>

                <IonCardContent>
                  {headerData.descrizione}
                </IonCardContent>
              </IonCard>
            </div>
          </IonSlide>)
      })}

    </IonSlides>


  );
};

export default HeaderCards;
