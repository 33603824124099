import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonModal, IonThumbnail, IonTitle, IonToolbar } from '@ionic/react';
import { cartSharp, close, list } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import '../components/CSS/Cart.css';

import { CartFunctions, CartObject } from '../Logic/CapLocal';

interface ContainerProps {
  isOpen: boolean;
  setIsOpen: () => void;
  handleRemoveFromCart: (index: number) => void,
  ordersFunctions: any,
}

const Cart: React.FC<ContainerProps> = ({ isOpen, setIsOpen, handleRemoveFromCart, ordersFunctions, }) => {
  //VARIABILI --------------------------------------------
  const [cartProducts, setCartProducts] = useState<CartObject[]>([]);
  let price: number = 0;
  const history = useHistory();
  //CONDIZIONI -------------------------------------------

  const handleUpdateItemsOnCart = async () => {
    setCartProducts(await CartFunctions.getProducts());
  }

  const handleDoOrder = () => {
    ordersFunctions.effettuaOrdine(history).then(() => {
      setIsOpen();
    });
  }

  useState(async () => {
    console.log("Cart useState")
    setCartProducts(await CartFunctions.getProducts());
  });

  useEffect(() => {
    console.log("Trigger Cart update");
    let x: any = [];
    const getData = async () => {
      x = await CartFunctions.getProducts();
      return setCartProducts(x);
    }
    getData();

  }, [isOpen]);
  //RETURN -----------------------------------------------
  return (
    <IonModal isOpen={isOpen}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => setIsOpen()}>Chiudi</IonButton>
          </IonButtons>
          <IonTitle>Carrello</IonTitle>
          <IonButtons slot="end">
            <IonButton color={"primary"} fill='solid' routerLink='/tavolo' onClick={() => setIsOpen()}> {"Tavolo"}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>

        {cartProducts.length > 0 ?
          <>
            <IonList className='cart-list'>
              {cartProducts?.map((product: CartObject, index: number) => {
                price += parseFloat(product.product.prezzo)
                return (
                  <IonItem key={"CartProduct" + index}>
                    <IonThumbnail>
                      <IonImg src={product.product.immaginiUrl[0]}></IonImg>
                    </IonThumbnail>
                    <IonLabel className='ion-margin-start'>
                      <h3>{product.product.nome}</h3>
                      <h3>€ {product.product.prezzo}</h3>

                      {product.withOut.length > 0 ?
                        <p className="ion-text-wrap">Senza:
                          {product.withOut.map((ing: any, indexIng: number) => {
                            return (
                              <span key={"product.withOut.map" + indexIng}>{ing.nome}, </span>
                            )
                          })}
                        </p>
                        : null}
                    </IonLabel>
                    <IonButton onClick={() => { handleRemoveFromCart(index); handleUpdateItemsOnCart() }} fill='clear' color={"danger"} slot='end'>
                      <IonIcon icon={close} />
                    </IonButton>
                  </IonItem>
                )
              })}
            </IonList>
            <IonButton className="ion-margin" onClick={() => { handleDoOrder() }} color={"success"} expand='block'>
              <IonIcon icon={cartSharp} className='ion-margin-end' />
              Ordina subito €{price}
            </IonButton>
          </>
          :
          <div className="ion-padding">
            <IonLabel>
              <h3 className='ion-text-center'>Il carrello è vuoto</h3>
            </IonLabel>
            <IonButton routerLink='/prodotti' onClick={() => setIsOpen()} color={"primary"} expand='block' className='ion-margin-top'>
              <IonIcon icon={list} className='ion-margin-end' />
              Vai a tutti i prodotti
            </IonButton>
          </div>
        }
      </IonContent>
    </IonModal>
  );
};

export default Cart;
