import { IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonContent, IonHeader, IonIcon, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { calendarOutline } from 'ionicons/icons';
import './CSS/BookPage.css';


type PageProps = {
  data: any
}

const BookPage: React.FC<PageProps> = ({ data }) => {

  //VARIABILI --------------------------------------------
  const dataPage = data.orari;
  //CONDIZIONI -------------------------------------------
  // const handlePrenota = () => {
  //   console.log("Heh, nella prossima versione! ;)")
  // }
  //RETURN -----------------------------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Orari</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Orari</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div>
          {
            dataPage?.map((orario: any, index: number) => {
              return (
                <IonCard
                  key={index + "card-orario"}
                  className="card-orario"
                >
                  <IonCardHeader>
                    <IonCardSubtitle>
                      <IonIcon icon={calendarOutline} className="ion-margin-end" />
                      {orario.giorno}
                    </IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                    {orario.orario?.map((ora: any, index: number) => {
                      if (ora.chiuso) {
                        return (
                          <p key={index + "ora" + orario.giorno}>
                            Chiuso
                          </p>
                        )
                      } else {
                        return (
                          <p key={index + "ora" + orario.giorno}>
                            {ora.inizio} - {ora.fine}
                          </p>
                        )
                      }
                    })}
                  </IonCardContent>
                  {/* {
                    orario.orario[0] !== "Chiuso" ?

                      <IonButton fill='clear' size='small' onClick={() => handlePrenota()}>
                        <IonIcon icon={bookOutline} className="ion-margin-end" />
                        Prenota un tavolo
                      </IonButton>
                      : null
                  } */}
                </IonCard>
              )
            })
          }
        </div>

      </IonContent>
    </IonPage>
  );
};

export default BookPage;
