import { Preferences } from "@capacitor/preferences";

export interface CartObject {
  product: any;
  withOut: any[];
}

export const CartFunctions = {
  saveProducts: async function saveProduct(cartProducts: CartObject[]) {
    // console.log("SET ON LOCAL : ", cartProducts);
    await Preferences.set({
      key: "cart",
      value: JSON.stringify(cartProducts),
    });
  },

  getProducts: async function getProducts() {
    const { value } = await Preferences.get({ key: "cart" });
    // console.log("GOT FROM LOCAL : ", value);
    if (value != null) {
      return JSON.parse(value);
    } else {
      return [];
    }
  },
};
