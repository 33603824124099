import { IonBackButton, IonButtons, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import './CSS/NewsPage.css';

type PageProps = {
  data: any,
}



const NewsPage: React.FC<PageProps> = ({ data }) => {

  //VARIABILI --------------------------------------------
  const { nomeNews } = useParams<{ nomeNews: string; }>();

  const nomePagina = nomeNews.split('-')[0];
  const indiceNews = nomeNews.split('-')[1];
  const dataPage = data.comunicazioni[parseInt(indiceNews)];

  //CONDIZIONI -------------------------------------------


  //RETURN -----------------------------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text={"Indietro"} defaultHref={"/comunicazioni"} />
          </IonButtons>
          <IonTitle>Comunicazioni</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Comunicazioni</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* CONTENUTO */}
        <div>

          {dataPage.immagineUrl ?

            <div className='div-img-news'>
              <img src={dataPage.immagineUrl} alt={"immagine news" + nomePagina} className="img-news" />
            </div>
            : null}

          <IonCard>
            <IonCardHeader>
              <IonCardSubtitle>{dataPage.sottotitolo}</IonCardSubtitle>
              <IonCardTitle>{dataPage.nome}</IonCardTitle>
            </IonCardHeader>
          </IonCard>

          <div className='ion-padding-horizontal'>
            <h3>{dataPage.descrizione}</h3>

            <p >
              {dataPage?.paragrafi}
            </p>

          </div>


        </div>
      </IonContent>
    </IonPage>
  );
};

export default NewsPage;
