import { IonAvatar, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon, IonImg } from '@ionic/react';
import { globeOutline, logoFacebook, logoInstagram, logoLinkedin } from 'ionicons/icons';
import './CSS/WorkerCard.css';


interface ContainerProps {
  data: any;
}

const WorkerCard: React.FC<ContainerProps> = ({ data }) => {
  //VARIABILI --------------------------------------------
  //CONDIZIONI -------------------------------------------
  //RETURN -----------------------------------------------
  return (
    <IonCard>
      <IonCardHeader className='title-row'>
        <IonCardSubtitle>
          {data.mansione}
        </IonCardSubtitle>
        <IonCardTitle className='display-flex'>
          <IonAvatar className='display-flex'>
            <IonImg alt={'immagine personale' + data.nome} src={data.immagineUrl} />
          </IonAvatar>
          <span className='ion-margin-start'>
            {data.nome}
          </span>
        </IonCardTitle>
        <IonCardSubtitle className='ion-margin-top'>
          {data.titoloDiStudio}
        </IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        {data.descrizione}
      </IonCardContent>

      {data.facebook ?
        <IonButton
          fill='clear'
          onClick={() => window.open(data.facebook, "_blank")}
        >
          <IonIcon icon={logoFacebook} />
        </IonButton>
        : null}
      {data.instagram ?
        <IonButton
          fill='clear'
          onClick={() => window.open(data.instagram, "_blank")}
        >
          <IonIcon icon={logoInstagram} />
        </IonButton>
        : null}
      {data.linkedin ?
        <IonButton
          fill='clear'
          onClick={() => window.open(data.linkedin, "_blank")}
        >
          <IonIcon icon={logoLinkedin} />
        </IonButton>
        : null}
      {data.sitoWeb ?
        <IonButton
          fill='clear'
          onClick={() => window.open(data.sitoWeb, "_blank")}
        >
          <IonIcon icon={globeOutline} />
        </IonButton>
        : null}
    </IonCard>
  );
};

export default WorkerCard;
