import { IonBadge, IonButton, IonButtons, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonMenuButton, IonPage, IonSearchbar, IonTitle, IonToolbar } from '@ionic/react';
import { cartSharp, chevronUp } from 'ionicons/icons';
import React, { useEffect } from 'react';
import Cart from '../components/Cart';
import SingleProductStandard from '../components/SingleProductStandard';
import './CSS/AllProductsPage.css';

type PageProps = {
  data: any,
  handleRemoveFromCart: (index?: number) => void;
  cartItemsNumber: number;
  ordersFunctions: any
}

const AllProductsPage: React.FC<PageProps> = ({ data, handleRemoveFromCart, cartItemsNumber, ordersFunctions }) => {

  //VARIABILI --------------------------------------------
  const dataPage = data?.sezioni;
  const [searchTextModale, setSearchTextModale] = React.useState('');
  const scrollRef = React.useRef<HTMLIonContentElement | null>(null);

  const [uniqueProducts, setUniqueProducts] = React.useState<any[]>(data);

  const [isModalOpen, setIsModalOpen] = React.useState(false);


  //CONDIZIONI -------------------------------------------
  const handleSearch = (testoCercato: string) => {
    let arr = Array.from(window.document.getElementsByClassName("product-card"));
    const query = testoCercato.toLowerCase();
    requestAnimationFrame(() => {
      arr.forEach((item: any) => {
        const shouldShow = item.textContent.toLowerCase().indexOf(query) > -1;
        item.style.display = shouldShow ? 'block' : 'none';
      });
    });
  }
  const scrollToTop = () => {
    scrollRef.current && scrollRef.current.scrollToTop(500);
  }

  useEffect(() => {
    setUniqueProducts(data);
  }, [data])

  //RETURN -----------------------------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Tutti i prodotti</IonTitle>
          <IonButtons slot='end'>
            <IonButton onClick={() => { setIsModalOpen(!isModalOpen) }}>
              <IonIcon icon={cartSharp} />
              <IonBadge slot="end">{cartItemsNumber}</IonBadge>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen scrollEvents={true} ref={scrollRef}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Tutti i prodotti</IonTitle>
          </IonToolbar>
        </IonHeader>

        <Cart ordersFunctions={ordersFunctions} isOpen={isModalOpen} setIsOpen={() => setIsModalOpen(false)} handleRemoveFromCart={(ev) => { handleRemoveFromCart(ev); }} />


        {/* CONTENUTO */}
        <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton onClick={() => scrollToTop()} >
            <IonIcon icon={chevronUp}></IonIcon>
          </IonFabButton>
        </IonFab>

        <IonSearchbar value={searchTextModale} placeholder={"Cerca prodotto"} onIonChange={e => { setSearchTextModale(e.detail.value!); handleSearch(e.detail.value!) }}></IonSearchbar>

        {uniqueProducts?.map((prodotto: any, index: number) => {
          return (
            <div key={"AllProductsPage" + index}>
              <SingleProductStandard data={prodotto} />
            </div>
          )
        })}



      </IonContent>
    </IonPage>
  );
};

export default AllProductsPage;
