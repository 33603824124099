import { IonBadge, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonList, IonMenuButton, IonPage, IonSearchbar, IonTitle, IonToolbar } from '@ionic/react';
import { cartSharp } from 'ionicons/icons';
import './CSS/SingleCategoryPage.css';

import React, { useState } from 'react';
import Cart from '../components/Cart';
import { useParams } from 'react-router';
import SingleProductStandard from '../components/SingleProductStandard';


interface ContainerProps {
  dataUniqueCategory: any[] | undefined
  handleRemoveFromCart: (index?: number) => void;
  cartItemsNumber: number;
  ordersFunctions: any;
}


const SingleCategoryPage: React.FC<ContainerProps> = ({
  dataUniqueCategory,
  handleRemoveFromCart,
  cartItemsNumber,
  ordersFunctions,
}) => {

  //VARIABILI -----------------------
  const { idCategoria } = useParams<{ idCategoria: string; }>();
  const [dataCategory, setDataCategory] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [searchTextModale, setSearchTextModale] = React.useState('');
  //CONDITIONS -----------------------

  const handleSearch = (testoCercato: string) => {
    let arr = Array.from(window.document.getElementsByClassName("product-card"));
    const query = testoCercato.toLowerCase();
    requestAnimationFrame(() => {
      arr.forEach((item: any) => {
        const shouldShow = item.textContent.toLowerCase().indexOf(query) > -1;
        item.style.display = shouldShow ? 'block' : 'none';
      });
    });
  }

  React.useEffect(() => {
    console.log("SingleCategoryPage : ", dataUniqueCategory);
    if (dataUniqueCategory!.find((prod: any) => prod.id === parseInt(idCategoria))) {
      setDataCategory(dataUniqueCategory!.find((prod: any) => prod.id === parseInt(idCategoria)))
    }
  }, [idCategoria]);


  //RETURN --------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{dataCategory?.nome}</IonTitle>
          <IonButtons slot='end'>
            <IonButton onClick={() => { setIsModalOpen(!isModalOpen) }}>
              <IonIcon icon={cartSharp} />
              <IonBadge slot="end">{cartItemsNumber}</IonBadge>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <Cart ordersFunctions={ordersFunctions} isOpen={isModalOpen} setIsOpen={() => setIsModalOpen(false)} handleRemoveFromCart={(ev) => { handleRemoveFromCart(ev); }} />

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{dataCategory?.nome}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonSearchbar value={searchTextModale} placeholder={"Cerca prodotto"} onIonChange={e => { setSearchTextModale(e.detail.value!); handleSearch(e.detail.value!) }}></IonSearchbar>


        <IonList>
          {
            dataCategory?.prodotti?.map((prodotto: any, index: number) => {
              return (
                <div key={dataCategory.id + "SingleProductStandardCategory" + index}>
                  <SingleProductStandard data={prodotto} />
                </div>
              )
            })
          }
        </IonList>

      </IonContent>
    </IonPage>
  );
};

export default SingleCategoryPage;
