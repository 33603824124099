import { IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonContent, IonGrid, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import WorkerCard from '../components/WorkerCard';
import './Page.css';

type PageProps = {
  data: any
}

const PersonalPage: React.FC<PageProps> = ({ data }) => {
  // VARIABLES ------------------------------
  const dataPage = data.personale;
  // CONDITIONS -----------------------------



  // RETURN ---------------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Personale</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Personale</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* CONTENUTO */}
        <div>
          {
            dataPage?.map((persona: any, index: number) => {
              return (
                <span
                  key={index + "workerCard"}
                >
                  <WorkerCard
                    data={persona}
                  />
                </span>
              )
            })

          }
        </div>

      </IonContent>
    </IonPage>
  );
};

export default PersonalPage;
