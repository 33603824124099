export const dataProducts = [
  {
    id: 0,
    imageUrl: ["https://www.pngplay.com/wp-content/uploads/13/Mojito-PNG-Photo-Image.png", "https://www.pngplay.com/wp-content/uploads/13/Mojito-PNG-Photo-Image.png", "https://www.pngplay.com/wp-content/uploads/13/Mojito-PNG-Photo-Image.png"],
    category: "stagionalita",
    name: "Mojito",
    info1: "250",
    unit1: "ml",
    info2: "11%",
    unit2: "alc",
    price: 7,
    tag: {
      hashtag1: "Rum Chiaro",
      hashtag2: "Rum Chiaro",
      hashtag3: "Rum Chiaro",
      hashtag4: "Rum Chiaro",
      hashtag5: "Rum Chiaro",
      hashtag6: "Rum Chiaro"
    },
    ingredients: {
      ingredient1: "Rum Chiaro",
      ingredient2: "Foglie di menta",
      ingredient3: "Zucchero",
      ingredient4: "Lime",
      ingredient5: "Soda",
      ingredient6: "Ghiaccio",
    },
    description: "Il mojito è un cocktail molto famoso di orhashtagine cubana, apprezzatissimo dallo scrittore Ernest Hemingway e da Fidel Castro. Orhashtaginariamente pensato come drink da dopo cena, ancora oggi mantiene viva questa tradizione ma ormai le persone si gustano questo cocktail beverino a ogni ora del giorno, soprattutto in estate. Gli tag sono rum chiaro, foglie di menta e zucchero con succo di lime, ghiaccio e soda o acqua frizzante."
    , removedIngredients: []

  },
  {
    id: 1,
    imageUrl: ["https://www.anticasambuca.it/wp-content/uploads/2021/03/Liquorice-fashioned-450x450-c-default.png", "https://www.anticasambuca.it/wp-content/uploads/2021/03/Liquorice-fashioned-450x450-c-default.png", "https://www.anticasambuca.it/wp-content/uploads/2021/03/Liquorice-fashioned-450x450-c-default.png",],
    category: "stagionalita",
    name: "Old Fashioned",
    info1: "250",
    unit1: "ml",
    info2: "15%",
    unit2: "alc",
    price: 6.00,
    tag: {
      hashtag1: "Rum Chiaro",
      hashtag2: "Rum Chiaro",
      hashtag3: "Rum Chiaro",
      hashtag4: "Rum Chiaro",
      hashtag5: "Rum Chiaro",
      hashtag6: "Rum Chiaro"
    },
    ingredients: {
      ingredient1: "Whisky",
      ingredient2: "Angostura",
      ingredient3: "Zucchero",
    },
    description: "L'Old Fashioned è un cocktail per appassionati di whisky e ha una storia lunga e appassionante. Si tratta di un drink catalogato dalla IBA tra gli unforgingredienttables e nasce nella New York di fine Ottocento. Da qui deriva il suo name ma anche per i bicchieri old fashioned dove è servito. Si può preparare con Bourbon o con Rye Whiskey, una zollingredientta di zucchero sciolta nel bicchiere, due gocce di angostura, poca soda e del ghiaccio. La gradazione è molto alta, sui 30 gradi, ma l'Old Fashioned è comunque un cocktail raffinato sia per il suo colore ambrato che per il gusto deciso e speziato."
    , removedIngredients: []

  }
  ,
  {
    id: 2,
    imageUrl: ["https://www.pngplay.com/wp-content/uploads/13/Negroni-Transparent-PNG.png",],
    category: "stagionalita",
    name: "Negroni",
    info1: "200",
    unit1: "ml",
    info2: "20%",
    unit2: "alc",
    price: 8.00,
    tag: {
      hashtag1: "Rum Chiaro",
      hashtag2: "Rum Chiaro",
      hashtag3: "Rum Chiaro",
      hashtag4: "Rum Chiaro",
      hashtag5: "Rum Chiaro",
      hashtag6: "Rum Chiaro"
    },
    ingredients: {
      ingredient1: "Gin",
      ingredient2: "Vermut",
      ingredient3: "Bitter Campari",
    },
    description: "Il Negroni è un cocktail italiano alcolico amato in tutto il mondo ed è riconoscibile dal colore arancio scuro e dal gusto particolare e unico: il sapore dolce del vermut rosso incontra quello amaro del bitter e il ringredientrogusto forte del gin in una miscela eccezionale di sapori che rende ancora il Negroni un drink richiestissimo. La sua storia comincia nel 1919 a Firenze quando il conte Camillo Negroni, abitudinario del Caffè Casoni, abbandonò il suo solito aperitivo, l'Americano, e chiese al barman, tale Folco Scarselli, una spruzzata di gin al posto del consuingrediento seltz. Questa variazione piacque talmente tanto alla clientela che, da quel momento, il cocktail Americano cominciò a chiamarsi l'Americano alla maniera del conte Negroni. Così nacque il Negroni, arrivato sino a noi, a base di bitter Campari, vermut e gin con la classica fingredientta d arancia, uno degli aperitivi più alcolici tra quelli conosciuti grazie ad una gradazione alcolica di 28°."
    , removedIngredients: []

  }
  ,
  {
    id: 3,
    imageUrl: ["https://www.pngplay.com/wp-content/uploads/13/Daiquiri-PNG-Images-HD.png", "https://www.pngplay.com/wp-content/uploads/13/Daiquiri-PNG-Images-HD.png"],
    category: "stagionalita",
    name: "Daiquiri",
    info1: "150",
    unit1: "ml",
    info2: "11%",
    unit2: "alc",
    price: 11,
    tag: {
      hashtag1: "Rum Chiaro",
      hashtag2: "Rum Chiaro",
      hashtag3: "Rum Chiaro",
      hashtag4: "Rum Chiaro",
      hashtag5: "Rum Chiaro",
      hashtag6: "Rum Chiaro"
    },
    ingredients: {
      ingredient1: "Rum bianco",
      ingredient2: "Succo lime",
      ingredient3: "Sciroppo di zucchero"
    }
    , description: "Il Negroni è un cocktail italiano alcolico amato in tutto il mondo ed è riconoscibile dal colore arancio scuro e dal gusto particolare e unico: il sapore dolce del vermut rosso incontra quello amaro del bitter e il ringredientrogusto forte del gin in una miscela eccezionale di sapori che rende ancora il Negroni un drink richiestissimo. La sua storia comincia nel 1919 a Firenze quando il conte Camillo Negroni, abitudinario del Caffè Casoni, abbandonò il suo solito aperitivo, l'Americano, e chiese al barman, tale Folco Scarselli, una spruzzata di gin al posto del consuingrediento seltz. Questa variazione piacque talmente tanto alla clientela che, da quel momento, il cocktail Americano cominciò a chiamarsi l'Americano alla maniera del conte Negroni. Così nacque il Negroni, arrivato sino a noi, a base di bitter Campari, vermut e gin con la classica fingredientta d arancia, uno degli aperitivi più alcolici tra quelli conosciuti grazie ad una gradazione alcolica di 28°."
    , removedIngredients: []

  }
]

