import { IonBadge, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonLabel, IonList, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { cartSharp, shareOutline } from 'ionicons/icons';
import { useState } from 'react';
import { presentShareActionUI } from '../Logic/Allerts';
import './Page.css';
import Cart from '../components/Cart';
import SectionCard from '../components/SectionCard';
import HeaderCards from '../components/HeaderCards';
import { isPlatform } from '@ionic/react';


interface PAGE_PROPS {
  ordersFunctions: any,
  presentShareAction: any,
  data: any,
  cartItemsNumber: number;
  handleRemoveFromCart: (index?: number) => void;
}


const HomePage: React.FC<PAGE_PROPS> = ({
  ordersFunctions,
  presentShareAction,
  data,
  cartItemsNumber,
  handleRemoveFromCart
}) => {


  // VARIABLES ------------------------------

  const [isModalOpen, setIsModalOpen] = useState(false);

  // CONDITIONS -----------------------------
  // RETURN ---------------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          {isPlatform('ios') ?
            null : <IonTitle>{data?.nome}</IonTitle>
          }
          <IonButtons slot='end'>
            <IonButton fill='clear' color={"secondary"} className='ion-margin' onClick={() => { presentShareActionUI(presentShareAction) }}>
              Condividi
              <IonIcon icon={shareOutline} className='ion-margin-start' />
            </IonButton>
            <IonButton onClick={() => { setIsModalOpen(!isModalOpen) }}>
              <IonIcon icon={cartSharp} />
              <IonBadge slot="end">{cartItemsNumber}</IonBadge>
            </IonButton>

          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <Cart isOpen={isModalOpen} setIsOpen={() => setIsModalOpen(false)} handleRemoveFromCart={(ev) => { handleRemoveFromCart(ev); }} ordersFunctions={ordersFunctions} />

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{data?.nome}</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* CONTENT -------------------------- */}
        <HeaderCards data={data?.comunicazioni} />

        <div className='ion-padding'>
          <IonLabel>
            <p className='ion-text-center ion-padding'>I nostri prodotti più apprezzati</p>
          </IonLabel>
          <IonList className='ion-item padding-zero'>
            {/* {dataProducts.map((product: any, index: number) => {
              return (
                <ProductStandard key={index + "homeprod"} obj={product} productsFunctions={productsFunctions} presentToastAddedToCart={presentToastAddedToCart} />
              )
            })} */}
          </IonList>
        </div>

        <div>
          <IonLabel>
            <p className='ion-text-center ion-padding'>Le nostre sezioni</p>
          </IonLabel>
          <SectionCard data={data?.sezioni} />
        </div>

      </IonContent>
    </IonPage>
  );
};

export default HomePage;
