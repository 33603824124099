
export let tableNumber: string | undefined | null;
export let virtualTableNumber: string | undefined | null;
export let generatedPinTable: string | undefined | null;
export let userType: string | undefined | null;
export let orders: any | undefined | null = [];
export let whenTableActivated: string | undefined | null;
export let currentSession: string | undefined | null;
export let ordersInCart: any = [];


export const LocalstorageData = {
     getTableNumber: function () {
          return tableNumber;
     },
     getVirtualTableNumber: function () {
          return virtualTableNumber;
     },
     getGeneratedPinTable: function () {
          return generatedPinTable;
     },
     getUserType: function () {
          return userType;
     },
     getWhenTableActivated: function () {
          return whenTableActivated;
     },
     getCurrentSession: function () {
          return currentSession;
     },
     getOrdersInCart: function () {
          return ordersInCart;
     }
}

export const LocalstorageRead = {
     readTableNumber: async function () {
          let aux = localStorage.getItem('tableNumber');
          console.log("ReadedData: ", aux);
          tableNumber = aux;
     },

     readVirtualTableNumber: async function () {
          let aux = localStorage.getItem('virtualTableNumber');
          console.log("ReadedData: ", aux);
          virtualTableNumber = aux;
     },

     readGeneratedPinTable: async function () {
          let aux = localStorage.getItem('generatedPinTable');
          console.log("ReadedData: ", aux);
          generatedPinTable = aux;
     },

     readUserType: async function () {
          let aux = localStorage.getItem('userType');
          console.log("ReadedData: ", aux);
          userType = aux;
     },
     readWhenTableActivated: function () {
          let aux = localStorage.getItem('whenTableActivated');
          console.log("ReadedData: ", aux);
          whenTableActivated = aux;
     },
     readCurrentSession: function () {
          let aux = localStorage.getItem('currentSession');
          console.log("ReadedData: ", aux);
          currentSession = aux;
     },
     readOrdersInCart: function () {
          let aux = localStorage.getItem('ordersInCart');
          console.log("ordersInCart: ", aux);
          if (aux != null || aux != undefined) {
               ordersInCart = JSON.parse(aux);
          }
     }
}

export const LocalstorageWrite = {
     writeVirtualTableNumber: async function (virtualTableNumber: string) {
          return localStorage.setItem('virtualTableNumber', virtualTableNumber);
     },
     writeGeneratedPinTable: async function (generatedPinTable: string) {
          return localStorage.setItem('generatedPinTable', generatedPinTable);
     },
     writeUserType: async function (userType: string) {
          return localStorage.setItem('userType', userType);
     },
     writeWhenTableActivated: async function () {
          let dateNow = new Date();
          return localStorage.setItem('whenTableActivated', JSON.stringify(dateNow));
     },
     writeCurrentSession: async function (tableNumber: string) {
          return localStorage.setItem('currentSession', tableNumber);
     },
     writeOrdersInCart: async function (orders: any) {
          return localStorage.setItem('ordersInCart', JSON.stringify(orders));
     },
     writeTableNumber: async function (tableNumber: any) {
          return localStorage.setItem('tableNumber', tableNumber);
     },
}

export const LocalstorageInitializze = () => {
     LocalstorageRead.readGeneratedPinTable();
     LocalstorageRead.readTableNumber();
     LocalstorageRead.readUserType();
     LocalstorageRead.readVirtualTableNumber();
     LocalstorageRead.readWhenTableActivated();
     LocalstorageRead.readCurrentSession();
     LocalstorageRead.readOrdersInCart();
}

export const LocalstorageWriteAccess = (generatedPinTable: string) => {
     LocalstorageWrite.writeGeneratedPinTable(generatedPinTable);
     LocalstorageWrite.writeUserType("userMaster");
     LocalstorageWrite.writeWhenTableActivated();
}

export const LocalstorageReadToDoOrder = () => {
     LocalstorageRead.readCurrentSession();
     LocalstorageRead.readTableNumber();
     LocalstorageRead.readGeneratedPinTable();
}


//----------------------------------------------------------------

export const LocalstorageDirectRead = {
     ordersInCart: function () {
          let x = JSON.parse(localStorage.getItem('ordersInCart')!);
          if (x != null) {
               return x;
          } else {
               return [];
          }
     },
     tableNumber: function () {
          let x = localStorage.getItem('tableNumber')!;
          return x;
     },
     currentSession: function () {
          let x = localStorage.getItem('currentSession')!;
          return x;
     },
     generatedPinTable: function () {
          let x = localStorage.getItem('generatedPinTable')!;
          return x;
     },
     whenTableActivated: function () {
          let x = localStorage.getItem('whenTableActivated')!;
          return x;
     },
}


//-----------------------------------------------------------------
export const LocalstorageDeleteElement = {
     ordersInCart: async function () {
          console.log("LocalstorageDeleteElement : ordersInCart");
          return localStorage.removeItem("ordersInCart");
     },
     notTableNumber: async function () {
          console.log("LocalstorageDeleteElement : notTableNumber");
          localStorage.clear()
          localStorage.setItem('tableNumber', tableNumber!);
          return
     },
     checkRequestedAction: async function () {
          // console.log("LocalstorageDeleteElement : all elements tranne tableNumber");
          localStorage.removeItem("tableNumber");
          localStorage.removeItem("ordersInCart");
          localStorage.removeItem("generatedPinTable");
          localStorage.removeItem("userType");
          localStorage.removeItem("whenTableActivated");
          localStorage.removeItem("currentSession");
     },
     resetSession: async function () {
          localStorage.clear()
     },
}
