import { IonBackButton, IonBadge, IonButton, IonButtons, IonChip, IonContent, IonHeader, IonIcon, IonLabel, IonPage, IonSlide, IonSlides, IonTitle, IonToolbar } from '@ionic/react';
import { add, bookmark, cartSharp, checkmark, close, } from 'ionicons/icons';
import { useParams } from 'react-router';
import './CSS/ProductPage.css';

import { homeUri } from '../elements';
import React, { useState } from 'react';
import Cart from '../components/Cart';


interface ContainerProps {
  dataUniqueProducts: any[] | undefined,
  addToCard: (prodotto: any, removedIngredients: any[]) => void;
  handleRemoveFromCart: (index?: number) => void;
  cartItemsNumber: number;
  ordersFunctions: any;
}


const SingleProductPage: React.FC<ContainerProps> = ({
  dataUniqueProducts,
  addToCard,
  handleRemoveFromCart,
  cartItemsNumber,
  ordersFunctions
}) => {

  //VARIABILI -----------------------

  const slideOptsChips = {
    initialSlide: 0,
    speed: 1000,
    slidesPerView: 3,
  }
  const slideOptsImmagini = {
    initialSlide: 0,
    speed: 2000,
    slidesPerView: 1,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    }
  }
  const [dataProdotto, setDataProdotto] = useState<any>();
  const { idProdotto } = useParams<{ idProdotto: string }>();

  const [removedIngredients, setRemovedIngredients] = useState<any[]>([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  //CONDITIONS -----------------------

  const handleRemoveIngredients = (ingredient: any) => {
    if ((removedIngredients.find((ing: any) => ing.id === ingredient.id) === undefined))
      setRemovedIngredients(removedIngredients.concat(ingredient));
  }

  const handleRestoreIngredient = (index: number) => {
    setRemovedIngredients((removedIngredients) => removedIngredients.filter((_, i) => i !== index))
  }


  React.useEffect(() => {
    if (dataUniqueProducts!.find((prod: any) => prod.id === parseInt(idProdotto))) {
      setDataProdotto(dataUniqueProducts!.find((prod: any) => prod.id === parseInt(idProdotto)))
    }

  }, [dataUniqueProducts]);


  //RETURN --------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text={"Indietro"} defaultHref={homeUri} color={'primary'}></IonBackButton>
          </IonButtons>
          <IonTitle>{dataProdotto?.nome}</IonTitle>
          <IonButtons slot='end'>
            <IonButton onClick={() => { setIsModalOpen(!isModalOpen) }}>
              <IonIcon icon={cartSharp} />
              <IonBadge slot="end">{cartItemsNumber}</IonBadge>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <Cart ordersFunctions={ordersFunctions} isOpen={isModalOpen} setIsOpen={() => setIsModalOpen(false)} handleRemoveFromCart={(ev) => { handleRemoveFromCart(ev); }} />

      <IonContent fullscreen>
        <div>
          <IonSlides options={slideOptsImmagini}>
            {dataProdotto?.immaginiUrl.map((value: string, index: number) => {
              return (
                <IonSlide key={index + "image"} className='slide-img-prod'>
                  <img src={value} alt="" className="img-prod" />
                </IonSlide>
              )
            })}
          </IonSlides>
        </div>

        <div className='div-descrizione ion-padding'>
          <IonLabel className='ion-padding ion-item'>
            <div className='grid product-grid '>
              <div className='titolo-prodotto '>
                <h2 className='color-white'> <b>{dataProdotto?.nome}</b></h2>
              </div>
              <div className='productPage-price-text'>
                <span className='to-right'> <b className='to-right color-white'>€ {dataProdotto?.prezzo}</b> </span>
              </div>
            </div>
            <p className='color-white'> {dataProdotto?.tags[0]}</p>

            <IonButton expand='block' color={"light"} className="ion-margin-top" onClick={(ev) => {
              addToCard(dataProdotto!, removedIngredients);
            }}>
              Aggiungi <IonIcon icon={add}></IonIcon>
            </IonButton>


            <p className='color-white ion-padding-top'>Ingredienti:</p>
            <IonLabel>
              <p className='ion-padding-bottom'>Clicca sugli ingredienti per deselezionarli</p>
            </IonLabel>

            {dataProdotto?.ingredienti?.map((ingrediente: any, index: number) => {
              return (
                <IonChip key={index + "chipingrediente-productScreen" + ingrediente} outline={true} color={"light"} onClick={() => { handleRemoveIngredients(ingrediente) }}>
                  <IonLabel className="productPage-chip-text-removed">{ingrediente.nome}</IonLabel>
                  <IonIcon icon={checkmark} className="productPage-icon-width"></IonIcon>
                </IonChip>

              )
            })
            }

            <br />

            {removedIngredients.length > 0 ?
              <>
                <p className='color-white ion-padding-bottom ion-padding-top'>
                  Ingredienti rimossi:
                </p>
                {removedIngredients.map((ingredient: any, index: number) => {
                  return (
                    <IonChip outline={true} color={"dark"} onClick={() => { handleRestoreIngredient(index) }} key={index + "chipingrediente-productScreen-rimpsso"}>
                      <IonLabel className="productPage-chip-text-removed">{ingredient.nome}</IonLabel>
                      <IonIcon icon={close} className="productPage-icon-width"></IonIcon>
                    </IonChip>
                  )
                })
                }

              </>
              : null}


            <p className='slides-padding-top color-white  ion-padding-bottom'>Etichette:</p>
            <IonSlides options={slideOptsChips}>

              {dataProdotto?.tags?.map((tag: string, index: number) => {
                return (
                  <IonSlide key={index + "etichette"}>
                    <IonChip outline={true} color={"light"} key={index + "chiptag" + tag}>
                      <IonIcon icon={bookmark}></IonIcon>
                      <IonLabel className="productPage-chip-text">{tag}</IonLabel>
                    </IonChip>
                  </IonSlide>
                )
              })
              }
            </IonSlides>


            <p className='slides-padding-top color-white'>Descrizione:</p>

            <div className='ion-padding ion-text-center color-white'>
              {dataProdotto?.descrizione}
            </div>
          </IonLabel>
        </div>


      </IonContent>
    </IonPage>
  );
};

export default SingleProductPage;
