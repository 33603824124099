import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from '@ionic/react';

import './CSS/Menu.css';

import { useLocation } from 'react-router-dom';
import { caretForwardCircleOutline, fingerPrint, happyOutline, helpCircle, homeOutline, listOutline, newspaperOutline, squareOutline, storefrontOutline, timeOutline } from 'ionicons/icons';
import { LocalstorageDirectRead } from '../Logic/Localstorage';
import { developerPageUri, personalUri, tableUri, whoWeAraPageUri } from '../elements';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}


const pages: AppPage[] = [
  {
    title: 'Home',
    url: "/",
    iosIcon: homeOutline,
    mdIcon: homeOutline
  },
  {
    title: 'Tavolo',
    url: tableUri,
    iosIcon: squareOutline,
    mdIcon: squareOutline
  },
  {
    title: 'Personale',
    url: personalUri,
    iosIcon: happyOutline,
    mdIcon: happyOutline
  },
  {
    title: 'Chi siamo',
    url: whoWeAraPageUri,
    iosIcon: storefrontOutline,
    mdIcon: storefrontOutline
  },
  // {
  //   title: 'Sessione',
  //   url: sessionPageUri,
  //   iosIcon: wifi,
  //   mdIcon: wifi
  // },
  {
    title: 'Comunicazioni',
    url: '/comunicazioni',
    iosIcon: newspaperOutline,
    mdIcon: newspaperOutline
  },
  {
    title: 'Orari',
    url: '/orari',
    iosIcon: timeOutline,
    mdIcon: timeOutline
  },
  {
    title: 'Developer',
    url: developerPageUri,
    iosIcon: fingerPrint,
    mdIcon: fingerPrint
  },

];

interface PageProps {
  uniqueCategory: any
}


const Menu: React.FC<PageProps> = ({ uniqueCategory }) => {
  //VARIABLES -------------------------
  const location = useLocation();
  //CONDITIONS ------------------------

  //RETURN ----------------------------
  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>Menu</IonListHeader>
          <IonNote>Pin del tavolo  {LocalstorageDirectRead.tableNumber()} : {LocalstorageDirectRead.generatedPinTable()}</IonNote>
          <IonMenuToggle autoHide={false}>
            <IonItem lines="none" routerLink={"/tutorial"} routerDirection="forward">
              <IonIcon slot="start" ios={helpCircle} md={helpCircle} />
              <IonLabel>{'Come funziona'}</IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonList id="labels-list">
            <IonListHeader>Categorie</IonListHeader>
            {uniqueCategory?.map((category: any, index: number) => (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem lines="inset" key={index} routerLink={"/categoria/" + category.id} routerDirection="forward">
                  {/* <IonIcon slot="start" ios={caretForwardCircleOutline} md={caretForwardCircleOutline} /> */}
                  <IonLabel>{category.nome}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            ))}
            <IonMenuToggle autoHide={false}>
              <IonItem lines="inset" routerLink={"/prodotti"} routerDirection="forward">
                <IonIcon slot="start" ios={listOutline} md={listOutline} />
                <IonLabel>{'Tutti i prodotti'}</IonLabel>
              </IonItem>
            </IonMenuToggle>
          </IonList>
          <hr />
          {pages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="forward" lines="full" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>


      </IonContent>
    </IonMenu>
  );
};

export default Menu;
