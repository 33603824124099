import { IonBackButton, IonBadge, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonLabel, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { cartSharp } from 'ionicons/icons';
import { useState } from 'react';
import { useParams } from 'react-router';
import Cart from '../components/Cart';
import CategoryCard from '../components/CategoryCard';
import './CSS/SectionPage.css';


type PageProps = {
  data: any,
  handleRemoveFromCart: (index?: number) => void;
  cartItemsNumber: number;
  ordersFunctions: any
}

const SectionPage: React.FC<PageProps> = ({ data, handleRemoveFromCart, cartItemsNumber, ordersFunctions }) => {

  //VARIABILI --------------------------------------------
  const { indiceSezione } = useParams<{ indiceSezione: any; }>();

  const nomeSezione = indiceSezione.split('-')[0];
  const sectionIndex = indiceSezione.split('-')[1];
  const dataSezione = data.sezioni[sectionIndex];

  const [isModalOpen, setIsModalOpen] = useState(false);
  //CONDIZIONI -------------------------------------------

  //RETURN -----------------------------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text={"Home"} defaultHref={"/menu"} />
          </IonButtons>
          <IonTitle>{dataSezione.nome}</IonTitle>
          <IonButtons slot='end'>
            <IonButton onClick={() => { setIsModalOpen(!isModalOpen) }}>
              <IonIcon icon={cartSharp} />
              <IonBadge slot="end">{cartItemsNumber}</IonBadge>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <Cart ordersFunctions={ordersFunctions} isOpen={isModalOpen} setIsOpen={() => setIsModalOpen(false)} handleRemoveFromCart={(ev) => { handleRemoveFromCart(ev); }} />


      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{dataSezione.nome}</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* CONTENUTO */}
        <div>
          {/* immagine header */}
          <div>
            <img alt={"immagine header " + dataSezione.nome} src={dataSezione.immagineUrl} className="header-img" width={"100%"} />
          </div>
          {/* descrizione */}
          <div className='ion-padding'>
            <IonLabel>
              <p>Descrizione</p>
            </IonLabel>
            <p>{dataSezione.descrizione}</p>
          </div>

          {/* categorie */}
          <div>
            <h2 className='ion-padding-top ion-padding-horizontal'>Categorie</h2>
            <CategoryCard
              data={dataSezione.categorie}
              indiceSezione={sectionIndex}
              nomeSezione={nomeSezione}
            />
          </div>

        </div>
      </IonContent>
    </IonPage>
  );
};

export default SectionPage;
