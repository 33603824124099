
import { onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { arrayUnion, doc, setDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import { sha256 } from "js-sha256";
import { LocalstorageData, LocalstorageDirectRead, LocalstorageInitializze, LocalstorageWrite, LocalstorageWriteAccess } from "./Localstorage";
import { dataProducts } from "../data";



export let userUID: string | undefined | null;
export let generatedTablePin = (Math.floor(Math.random() * (999 - 100) + 100)).toString();
export let pinUsedToConnect: number | undefined | null;
export let tableNumber: number | undefined | null;


export let backendErrors = {
     userNotAuthenticated: {
          state: false,
          message: "Creazione sessione in corso..."
     },
     tableNotFree: {
          state: false,
          message: "Tavolo occupato."
     },
     wrongPin: {
          state: false,
          message: "Pin errato."
     },
     connectionError: {
          state: false,
          message: "Errore. Controllare la connessione."
     },
     genericError: {
          state: false,
          message: "Errore. Ricarica la pagina."
     },
}


export const BackendManagerGetData = {
     getUserUID: async function () {
          return userUID;
     },
     getGeneratedTablePin: async function () {
          return userUID;
     },
     getPinUsedToConnect: async function () {
          return pinUsedToConnect;
     },
     getTableNumber: async function () {
          return tableNumber;
     },
}


export const BackendManagerRealTable = {
     hanldeReserveTable: async function (tableNumber: string) {
          tableNumber = tableNumber;

          console.log("hanldeReserveTable by: ", userUID, " at table:", tableNumber, " with generated pin: ", generatedTablePin);

          await ServerAction.reserveTable(userUID!, tableNumber, generatedTablePin).catch((error) => {
               const errorCode = error.code;
               const errorMessage = error.message;
               console.log("FIREBASE ERROR: ", errorCode, errorMessage);
               errorCheck(errorCode);
          }).then(async () => {
               //Una volta completato il processo aggiungi dato current_session:
               LocalstorageWrite.writeCurrentSession(tableNumber);

               //Perchè il pin può esser stato cambiato
               LocalstorageInitializze();

               await ServerAction.addUserToTable(tableNumber, generatedTablePin).catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log("FIREBASE ERROR: ", errorCode, errorMessage);
                    errorCheck(errorCode);
               });
          });
     },
     handleConnectWithPin: async function (pinUsedToConnect: string, tableNumber: string) {
          tableNumber = tableNumber;
          pinUsedToConnect = pinUsedToConnect;
          console.log("handleConnectWithPin by: ", userUID, " at table:", tableNumber, " with pin used to connect:", pinUsedToConnect);
          await ServerAction.connectWithPin(userUID!, tableNumber, pinUsedToConnect).then(() => {
               backendErrors.wrongPin.state = false;
               console.log("Connesso tramite pin.");
               LocalstorageWrite.writeGeneratedPinTable(pinUsedToConnect);
               LocalstorageWrite.writeUserType("userGuest");
               LocalstorageWrite.writeWhenTableActivated();
               LocalstorageWrite.writeCurrentSession(tableNumber);

               LocalstorageInitializze();
          }).catch((error) => {
               const errorCode = error.code;
               const errorMessage = error.message;
               console.log("FIREBASE ERROR: ", errorCode, errorMessage);
               backendErrors.wrongPin.state = true;
          })
     }
}



export const ServerAction = {
     reserveTable: async function (userUID: string, tableNumber: string, generatedPinTable: string) {
          await updateDoc(doc(db, "RealTables", tableNumber), {
               pin: sha256(generatedPinTable.toString()),
               riservatedDate: new Date().toISOString(),
               isFree: false,
          }).then(() => {
               console.log("Tavolo riservato ");
               LocalstorageWriteAccess(generatedPinTable);
          })
     },
     connectWithPin: async function (userUID: string, tableNumber: string, pinUsedToConnect: string) {
          await updateDoc(doc(db, "RealTables", tableNumber), {
               pin: sha256(pinUsedToConnect.toString()),
               usersConnected: arrayUnion({
                    "userUID": userUID,
                    "when": new Date().toISOString()
               }),
          }).then(() => {
               console.log("Connesso al tavolo tramite PIN")
          })
     },
     addUserToTable: async function (tableNumber: string, pin: string) {
          await updateDoc(doc(db, "RealTables", tableNumber), {
               usersConnected: arrayUnion({
                    "userUID": userUID,
                    "when": new Date().toISOString()
               }),
               pin: sha256(pin.toString()),
          }).then(() => {
               console.log("Utente aggiunto al tavolo")
          })
     },
     addOrderToTable: async function (tableNumber: string, pin: string, orders: any, errorToast: any) {
          console.log("addOrderToTable: tn:", tableNumber, "pin :", pin, " orders: ", orders)

          LocalstorageInitializze(); //Lettura di sicurezza
          let currentSessionLocal = LocalstorageData.getCurrentSession();
          let pinLocal = LocalstorageData.getGeneratedPinTable();
          console.log("NEW WAY TO addOrderToTable: tn:", currentSessionLocal, "pin :", pinLocal, " orders: ", orders);


          await updateDoc(doc(db, "RealTables", currentSessionLocal!), {
               orders: arrayUnion({
                    "userUID": userUID,
                    "when": new Date().toLocaleTimeString(),
                    order: orders,
                    status: "Inviato"
               }),
               pin: sha256(pinLocal!.toString()),

          })
     },
     requestPersonalCheck: async function (orders: string, totalCheck: number) {
          LocalstorageInitializze(); //Lettura di sicurezza
          let currentSessionLocal = LocalstorageData.getCurrentSession();
          let pinLocal = LocalstorageData.getGeneratedPinTable();
          await updateDoc(doc(db, "RealTables", currentSessionLocal!), {
               requestedChecks: arrayUnion({
                    "userUID": userUID,
                    "when": new Date().toLocaleTimeString(),
                    order: orders,
                    status: "Inviato",
                    personalCheck: totalCheck,
               }),
               pin: sha256(pinLocal!.toString()),

          }).catch((error) => {
               console.log("Errore qui.");
          }).then(() => {
               console.log("Richiesta conto inviata")
          })
     },
     requestTotalCheck: async function (totalCheck: number) {
          LocalstorageInitializze(); //Lettura di sicurezza
          let currentSessionLocal = LocalstorageData.getCurrentSession();
          let pinLocal = LocalstorageData.getGeneratedPinTable();
          await updateDoc(doc(db, "RealTables", currentSessionLocal!), {
               totalCheck: arrayUnion({
                    "userUID": userUID,
                    "when": new Date().toLocaleTimeString(),
                    status: "Inviato",
                    totalCheck: totalCheck,
               }),
               pin: sha256(pinLocal!.toString()),
          }).catch((error) => {
               console.log("Errore qui.");
          }).then(() => {
               console.log("Richiesta conto inviata")
          })
     },

}


export const errorCheck = (errorCode: string) => {
     switch (errorCode) {
          case "permission-denied":
               backendErrors["tableNotFree"].state = true;
               console.log("permission-denied");
               break;

          default:
               backendErrors["genericError"].state = true;
               console.log("genericError");
               break;
     }
}

export const authenticateUser = async () => {
     await signInAnonymously(auth).catch((error) => {
          console.log(error);
     }).then(async () => {
          onAuthStateChanged(auth, (user) => {
               if (user) {
                    return userUID = user.uid;
               } else {
                    return userUID = null;
               }
          })
     }).catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log("FIREBASE ERROR Auth User: ", errorCode, errorMessage);
          return userUID = null;
     });
}

export const shareLink = () => {
     let message = "Unisciti al mio tavolo : ";
     let shareLink = window.location.hostname + "/invite-" + LocalstorageDirectRead.tableNumber() + "-code-" + LocalstorageDirectRead.generatedPinTable();
     window.open("https://wa.me/?text=" + message + shareLink);
}

export const loadProductTest = async () => {
     await setDoc(doc(db, "Products", "Homepage"), { dataProducts });
}