//Current url
export const currentPageUrl = "www.menuline.danielntll.it"


//Names
export const backButtonText = "Indietro";

//URI
export const homeUri = "/home";
export const cartUri = "/carello";
export const tableUri = "/tavolo";
export const catalogUri = "/catalogo";

export const catalog1Uri = "/Catalogo 1";
export const catalog2Uri = "/Catalogo 2";
export const catalog3Uri = "/Catalogo 3";
export const catalog4Uri = "/Catalogo 4";
export const catalog5Uri = "/Catalogo 5";
export const catalogAllUri = "/catalogo/Tutti i prodotti";



export const productPageUri = "/product";
export const sessionPageUri = "/sessione";
export const whoWeAraPageUri = "/chisiamo";

export const developerPageUri = "/developer";

export const personalUri = "/personale";

export const tutorialUri = "/tutorial";


//STATUS:
export const status1 = "Inviato";
export const status2 = "Preparazione in corso";
export const status3 = "Consegnato";