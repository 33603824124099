import { IonAvatar, IonButton, IonButtons, IonCard, IonCardContent, IonChip, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonMenuButton, IonModal, IonPage, IonRow, IonTitle, IonToolbar, useIonToast } from '@ionic/react';
import { doc, getDoc, onSnapshot, } from 'firebase/firestore';
import { exitOutline } from 'ionicons/icons';
import { useState } from 'react';
import { useHistory, } from 'react-router';
import { presentToastPersonalCheckUI } from '../Logic/Allerts';
import { ServerAction, userUID } from '../Logic/BackendManager';
import { LocalstorageDeleteElement, LocalstorageDirectRead, LocalstorageInitializze, } from '../Logic/Localstorage';
import { homeUri, status1, status2, status3 } from '../elements';
import { db } from '../firebase';
import './Page.css';


interface PAGE_PROPS {
  productsFunctions: any,
  ordersFunctions: any
}


const TablePage: React.FC<PAGE_PROPS> = ({ }) => {


  // VARIABLES ------------------------------
  let history = useHistory();
  const [presentPersonalCheck] = useIonToast();

  const [isOpenModalConto, setIsOpenModalConto] = useState(false);
  const [tableOrdersFromDatabase, setTableOrdersFromDatabase] = useState([]);

  const [personalCheck, setPersonalCheck] = useState(0);
  const [totalCheck, setTotalCheck] = useState(0);

  const [personalOrders, setPersonalOrders] = useState([]);

  let auxOrdiniPersonali: any = [];

  // CONDITIONS -----------------------------

  const handleAskPersonalCheck = async (personalOrders: any) => {
    await ServerAction.requestPersonalCheck(personalOrders, personalCheck).then(() => {
      presentToastPersonalCheckUI(presentPersonalCheck, "Apetta il cameriere al tavolo. Grazie!");
      setIsOpenModalConto(false);
      LocalstorageDeleteElement.checkRequestedAction();
      history.replace(homeUri);
    });
  }
  const handleAskTotalCheck = async () => {
    await ServerAction.requestTotalCheck(totalCheck).then(() => {
      presentToastPersonalCheckUI(presentPersonalCheck, "Apetta il cameriere al tavolo. Grazie!");
      setIsOpenModalConto(false);
      LocalstorageDeleteElement.checkRequestedAction();
      history.replace(homeUri);
    });
  }

  const prezzoPersonale = async (arraydata: any) => {
    let auxTotal = 0;
    let auxPersonal = 0;
    arraydata?.forEach((element: any) => {
      element.order?.forEach((elementorder: any) => {
        console.log(elementorder.product.nome, "--", elementorder.product.prezzo)
        auxTotal += parseInt(elementorder.product.prezzo);
        if (element.userUID == userUID) {
          auxPersonal += parseInt(elementorder.product.prezzo);
          auxOrdiniPersonali.push(elementorder.product);
        }
      });
    });
    setTotalCheck(auxTotal);
    setPersonalCheck(auxPersonal);
    setPersonalOrders(auxOrdiniPersonali);
    console.log("Ordini personali ", auxOrdiniPersonali);
  }

  function statusOrder(status: string) {
    switch (status) {
      case status1:
        return (
          <IonChip color={"warning"}>
            <IonLabel>{status}</IonLabel>
          </IonChip>
        )
        break;
      case status2:
        return (
          <IonChip color={"primary"}>
            <IonLabel>{status}</IonLabel>
          </IonChip>
        )

        break;
      case status3:
        return (
          <IonChip color={"success"}>
            <IonLabel>{status}</IonLabel>
          </IonChip>
        )
        break;

      default:
        return (
          <IonChip color={"tertiary"}>
            <IonLabel>In attesa...</IonLabel>
          </IonChip>
        )
        break;
    }
  }

  useState(async () => {
    LocalstorageInitializze();
    console.log("Usestate");
    const docRef = doc(db, "RealTables", LocalstorageDirectRead.currentSession());
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      setTableOrdersFromDatabase(docSnap.data().orders.reverse());
    } else {
      console.log("No such document!");
    }
    const unsub = onSnapshot(doc(db, "RealTables", LocalstorageDirectRead.currentSession()), (doc) => {
      setTableOrdersFromDatabase(doc.data()?.orders.reverse());
      prezzoPersonale(doc.data()?.orders);
      return console.log("New order");
    });

    return () => {
      unsub();
    }
  });


  // RETURN ---------------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Tavolo {LocalstorageDirectRead.currentSession()}</IonTitle>
          <IonButtons slot='end'>
            <IonButton color={"danger"} fill="outline" onClick={() => setIsOpenModalConto(true)}>
              Chiedi conto
              <IonIcon icon={exitOutline} className="ion-margin-start" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Tavolo {LocalstorageDirectRead.currentSession()}</IonTitle>
          </IonToolbar>
        </IonHeader>

        {/* CONTENT -------------------------- */}

        <div >

          {/* Real table data ---------------- */}
          {tableOrdersFromDatabase ?
            <>
              {tableOrdersFromDatabase?.map((order: any, index: any) => {
                // console.log(order)
                return (
                  <IonCard key={"orderCard" + index}>
                    <IonHeader className='ion-padding'>
                      Stato: {statusOrder(order?.status)}
                    </IonHeader>
                    <IonCardContent className=' ion-no-padding ion-padding-start ion-padding-end ion-padding-bottom'>

                      <IonList>
                        {order?.order?.map((element: any, index: number) => {
                          return (
                            <IonItem className='ion-item' key={element.product.id + "item" + index} >
                              <IonAvatar>
                                <img src={element.product.immaginiUrl[0]} />
                              </IonAvatar>
                              <IonLabel className='ion-padding' >
                                <h2> <b className="ion-text-wrap">{element.product.nome}</b></h2>
                                <p> <b >€ {element.product.prezzo}</b> </p>

                                {element.withOut.length > 0 ?
                                  <div className="ion-text-wrap">
                                    <p>Senza:</p>
                                    {element.withOut?.map((ingrediente: any, index: number) => {
                                      return (
                                        <IonChip color={"dark"} key={index + "ingremoved" + ingrediente}>
                                          <IonLabel>{ingrediente.nome}</IonLabel>
                                        </IonChip>
                                      )
                                    })
                                    }
                                  </div>
                                  : null}

                              </IonLabel>
                            </IonItem>

                          )
                        })}

                      </IonList>
                    </IonCardContent>
                    <IonFooter className='ion-padding'>
                      {order?.userUID == userUID ? "Tu" : "Guest"}  - Ora: {order?.when}
                    </IonFooter>
                  </IonCard>
                )
              })}
            </>
            :

            <div className='ion-padding'>
              <IonLabel>
                <p className='ion-text-center'>Non hai/avete ancora effettuato nessun ordine. Tutti gli ordini saranno visibili qui. </p>
              </IonLabel>
            </div>

          }


        </div>
        {/* MODALS , ALLERTS ----------------- */}


        <IonModal isOpen={isOpenModalConto} onDidDismiss={() => { setIsOpenModalConto(false); }} initialBreakpoint={0.5} breakpoints={[0, 0.25, 0.5, 0.75]}>
          <IonContent className="ion-padding">

            <IonGrid>
              <IonRow>
                <IonCol size='6'>
                  <IonLabel>
                    <h1>Tavolo</h1>
                  </IonLabel>
                </IonCol>
                <IonCol size='6'>

                </IonCol>
              </IonRow>
            </IonGrid>


            <br />

            <IonButton expand='block' color={"warning"} onClick={() => { handleAskPersonalCheck(personalOrders) }}>
              Chiedi conto personale: $ {personalCheck}
            </IonButton>
            <br />
            <IonButton expand='block' color={"danger"} onClick={() => { handleAskTotalCheck() }}>
              Chiedi conto totale:  $ {totalCheck}
            </IonButton>

          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default TablePage;
