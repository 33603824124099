import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/react';
import './CSS/SectionCard.css';



type SezioneData = {
  data: any[],
}

const SectionCard: React.FC<SezioneData> = ({ data }) => {
  //VARIABILI --------------------------------------------

  //CONDIZIONI -------------------------------------------

  //RETURN -----------------------------------------------
  return (
    <div>
      {data?.map((data: any, index: number) => {
        return (
          <IonCard
            key={index + "card-homeScreenSection"}
            routerLink={"/menu/" + data.nome + "-" + index}
          >
            <IonCardHeader>
              <IonCardTitle>{data.nome}</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
              {data.descrizione}
            </IonCardContent>
          </IonCard>
        )
      })
      }
    </div>
  )
};

export default SectionCard;
