import { IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './CSS/AllNewsPage.css';

type PageProps = {
  data: any,
}



const AllNewsPage: React.FC<PageProps> = ({ data }) => {

  //VARIABILI --------------------------------------------
  const dataPage = data.comunicazioni;
  //CONDIZIONI -------------------------------------------


  //RETURN -----------------------------------------------
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Comunicazioni</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Comunicazioni</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* CONTENUTO */}
        <div>
          {dataPage?.map((headerData: any, index: number) => {
            return (

              <IonCard
                key={index + "card-comunucazioni" + headerData.nome}
                routerLink={"/comunicazioni/" + headerData.nome + "-" + index}
              >
                <IonCardHeader>
                  <IonCardSubtitle>{headerData.sottotitolo}</IonCardSubtitle>
                  <IonCardTitle>{headerData.nome}</IonCardTitle>
                </IonCardHeader>

                <IonCardContent>
                  {headerData.descrizione}
                </IonCardContent>
              </IonCard>

            )
          })}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AllNewsPage;
